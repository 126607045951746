import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useShoppingContext } from "../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEdit,
  faEnvelope,
  faHome,
  faMailBulk,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import "./ShippingInfo.css";

const ShippingInfo = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { carrito, toggleCart, setInstrucciones, instrucciones } =
    useShoppingContext();
  const [instruccionesLength, setInstruccionesLength] = useState(0);

  const location = useLocation();
  const { shippingInfo } = location.state || {};

  const handleInstruccionesChange = (e) => {
    const text = e.target.value;
    setInstrucciones(text);
    setInstruccionesLength(text.length);
    // Actualizar instrucciones en el contexto si es necesario
    setInstrucciones(text);
  };

  return (
    <>
      <div className="shipping-info">
        <h1>CHECKOUT</h1>
        <PaymentMethods title="Método de pago"></PaymentMethods>
        <PaymentMethods title="Tipo de comprobante"></PaymentMethods>

        <div className="shipping-info-item flex-space-between">
          <span className="info-label ">
            <FontAwesomeIcon icon={faHome} /> {shippingInfo.direccion}
            {shippingInfo.distrito !== "Recojo en tienda" ? "," : ""}{" "}
            {shippingInfo.distrito}
          </span>
          <Link className="editar-direccion" to="/checkout/shipping">
            {" "}
            <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          </Link>
        </div>
        <div className="datos-personales">
          <div className="shipping-info-item flex-space-between">
            <span>
              <FontAwesomeIcon icon={faUser} />{" "}
              <span className="info-value">
                {shippingInfo.nombre} {shippingInfo.apellidos}
              </span>
            </span>
            <Link className="editar-datos" to="/checkout">
              {" "}
              <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>{" "}
            </Link>
          </div>
          <div className="shipping-info-item ">
            <FontAwesomeIcon icon={faPhone} />{" "}
            <span className="info-value">{shippingInfo.telefono}</span>
          </div>
          <div className="shipping-info-item">
            <FontAwesomeIcon icon={faAt} />{" "}
            <span className="info-value">{shippingInfo.email}</span>
          </div>
        </div>

        <div className="instrucciones">
          <textarea
            placeholder="Instrucciones adicionales"
            className="input-instrucciones"
            autoCapitalize="sentences"
            maxLength={200}
            value={instrucciones}
            onChange={handleInstruccionesChange}
          ></textarea>
          <div className="contador-caracteres">{instruccionesLength}/200</div>
        </div>
      </div>
    </>
  );
};

export default ShippingInfo;
