import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { useShoppingContext } from "../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./form.css";

const SignUpForm = () => {
  const navigate = useNavigate();
  const { loginData, setLoginData } = useAuth();
  const { showLoginForm, showSignUpForm, setShowLoginForm, setShowSignUpForm } =
    useShoppingContext();
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    obtenerUsuarios();
  }, []); // Obtener usuarios al cargar el componente

  const obtenerUsuarios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = (inputName) => {
    if (inputName === "password") {
      setShowPassword(!showPassword);
    } else if (inputName === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Resetear el mensaje de error
    setError("");
    // Verificar si los campos están vacíos
    if (
      !loginData.username ||
      !loginData.nombre ||
      !loginData.apellido ||
      !loginData.email ||
      !loginData.telefono ||
      !loginData.password ||
      !loginData.confirmPassword
    ) {
      setError("Todos los campos son requeridos");
      return;
    }

    // Verificar si el correo electrónico o el número de teléfono ya están registrados
    const emailExists = users.some((user) => user.email === loginData.email);
    const telefonoExists = users.some(
      (user) => user.telefono === loginData.telefono
    );
    const usernameExists = users.some(
      (user) => user.username === loginData.username
    );

    if (usernameExists) {
      setError("El usuario ya existe");
      return;
    }

    if (emailExists) {
      setError("El correo electrónico ya está registrado");
      return;
    }

    if (telefonoExists) {
      setError("El número de teléfono ya está registrado");
      return;
    }

    // Check if passwords match
    if (loginData.password !== loginData.confirmPassword) {
      setError("Las contraseñas no coinciden");
      return; // Prevent form submission
    }

    try {
      setLoginData({ ...loginData });

      const dataToSend = {
        ...loginData,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/createUser`,
        dataToSend
      );

      console.log("Respuesta del servidor:", response.data);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }

    setShowSignUpForm(false);
    setShowLoginForm(true);
  };

  return (
    <>
      <div className="flex-login-container">
        <div className="form-container">
          <div className="close-form-container">
            <FontAwesomeIcon
              icon={faTimes}
              className="form-close-button"
              onClick={() => {
                if (showLoginForm) {
                  setShowLoginForm(false);
                }
                if (showSignUpForm) {
                  setShowSignUpForm(false);
                }
              }}
            />
          </div>
          <form onSubmit={handleSubmit} className="register-form">
            <Link
              to="/"
              onClick={() => {
                if (showSignUpForm) {
                  setShowSignUpForm(false);
                }
              }}
            >
              <img width="300px" src="/images/logos/icon.png" alt="Logo" />
            </Link>
            <label className="form-label">
                Recomendado por:
                <input
                  type="text"
                  name="recomendado_por"
                  onChange={handleChange}
                  placeholder="nombre de usuario que recomienda (Opcional)"
                  className="form-input"
                  value={loginData.recomendado_por}
                />
              </label>
            <label className="form-label">
                Usuario
                <input
                  type="text"
                  name="username"
                  onChange={handleChange}
                  className="form-input"
                  value={loginData.username}
                />
              </label>
            <div className="flex-space-around">
              <label className="form-label">
                Nombre
                <input
                  type="text"
                  name="nombre"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Apellido
                <input
                  type="text"
                  name="apellido"
                  onChange={handleChange}
                  className="form-input"
                />
              </label>
            </div>
            <label className="form-label">
              Email
              <input
                type="email"
                name="email"
                onChange={handleChange}
                className="form-input"
              />
            </label>
            <label className="form-label">
              Teléfono
              <input
                type="text"
                name="telefono"
                maxLength={9}
                onChange={handleChange}
                className="form-input"
                inputMode="numeric"
                pattern="\d*"
              />
            </label>
            <label className="form-label">Contraseña</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={loginData.password}
                onChange={handleChange}
                autoComplete="off"
                className="form-input"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="password-icon"
                onClick={() => togglePasswordVisibility("password")}
              />
            </div>
            <label className="form-label">Repetir contraseña</label>
            <div className="password-input-container">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={loginData.confirmPassword}
                onChange={handleChange}
                autoComplete="off"
                className="form-input"
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="password-icon"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              />
            </div>
            {error && <div className="error-message">{error}</div>}{" "}
            {/* Mostrar el mensaje de error si existe */}
            <ul>
              <li>
                <button type="submit" className="form-button">
                  Registrarse
                </button>
              </li>
              <li>ó</li>
              <li>
                <p
                  className="pointer"
                  onClick={() => {
                    if (!showLoginForm) {
                      setShowLoginForm(true);
                    }
                    if (showSignUpForm) {
                      setShowSignUpForm(false);
                    }
                  }}
                >
                  Iniciar Sesión
                </p>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
