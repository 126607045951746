import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhatsAppButton from "../WhatsappButton/WhatsappButton";
import {
  faHistory,
  faList,
  faUsers,
  faStore,
  faGlobe,
  faBars,
  faHouse,
  faUser,
  faStar,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import {
  useAuth,
  useIsUserAdmin,
  useIsUserWorker,
  useIsUserDev,
} from "../../context/AuthContext";
import Carrito from "../../components/Carrito/Carrito";
import { useShoppingContext } from "../../context/ShoppingContext";
import SignUpForm from "../User/SignUpForm";
import SignInForm from "../User/SignInForm";
import UserMenu from "./UserMenu/UserMenu";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const { authenticatedUser } = useAuth();
  const isUserAdmin = useIsUserAdmin();
  const isUserWorker = useIsUserWorker();
  const isUserDev = useIsUserDev();

  const {
    carrito,
    vaciarCarrito,
    eliminarDelCarrito,
    modificarCantidad,
    modificarCantidadMenu,
    agregarMenu,
    eliminarMenu,
    selectedItems,
    setSelectedItems,
    handleItemSelectedChange,
    productos,
    setProductos,
    calcularTotal,
    calcularSubtotal,
    toggleCart,
    closeCart,
    cartOpen,
    userMenuOpen,
    toggleUserMenu,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
    showPopUp,
    setShowPopUp,
    toggleSidebar,
    isSidebarOpen,
    toggleMostros,
    handleAplicarDescuento,
  } = useShoppingContext();

  const [opcionesVisible, setOpcionesVisible] = useState(false);

  const toggleOpciones = () => {
    setOpcionesVisible(!opcionesVisible);
  };

  const hideOpciones = () => {
    setOpcionesVisible(false);
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop - 60, // Ajusta aquí el desplazamiento en píxeles
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    if (isSidebarOpen || cartOpen) {
      // Bloquear el scroll de la página cuando showExtras es true
      document.body.style.overflow = "hidden";
    } else {
      // Habilitar el scroll de la página cuando showExtras es false
      document.body.style.overflow = "auto";
    }

    // Limpiar el efecto cuando el componente se desmonta
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarOpen, cartOpen]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: {
          activo: true,
        },
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
        } else {
          console.error("No hay productos agregados.");
        }
      })
      .catch((error) => {
        console.error("Error al obtener productos activos:", error);
      });
  }, [setProductos]);

  useEffect(() => {
    const totalItems = carrito.reduce(
      (total, item) => total + item.cantidad,
      0
    );
    setCartItemCount(totalItems);
  }, [carrito]);

  const [cartItemCount, setCartItemCount] = useState(0);
  const [isTablet] = useState(window.innerWidth <= 768);

  return (
    <>
      {
        <header className="header-container">
          <ul className="header-ul">
            <li>
              <button
                className="sidebar-toggle"
                onClick={() => {
                  if (cartOpen) {
                    toggleCart();
                  }
                  if (userMenuOpen) {
                    toggleUserMenu();
                  }
                  if (showPopUp) {
                    setShowPopUp();
                  }
                  toggleSidebar();
                  hideOpciones();
                }}
              >
                <FontAwesomeIcon style={{ color: "#df2425" }} icon={faBars} />
              </button>
            </li>

            <li>
              <Link
                className="links-principales"
                to={isUserAdmin || isUserDev || isUserWorker ? "panel" : "/"}
                target="_self"
                onClick={() => {
                  if (cartOpen) {
                    toggleCart();
                  }
                  if (userMenuOpen) {
                    toggleUserMenu();
                  }
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                  hideOpciones();
                }}
              >
                <img
                  className={`header-logo`}
                  src="/images/logos/icon.png"
                  alt="Logo"
                  id="logo"
                />
              </Link>
            </li>
            <div style={{ display: "flex",
              alignItems: "center",
             }}>
              <li
                style={{ display: "flex" }}
                onClick={() => {
                  toggleUserMenu();
                  if (isSidebarOpen) {
                    toggleSidebar();
                  }
                }}
              >
                {authenticatedUser && (
                  <span className="userName">{authenticatedUser.username}</span>
                )}
                <div className="user-circle">
                  <div className="circle-container">
                    <FontAwesomeIcon className="user-icon" icon={faUser} />
                  </div>
                </div>{" "}
              </li>
              <li>
                <button
                  onClick={() => {
                    toggleCart();
                    hideOpciones();
                  }}
                  className="cart-div"
                >
                  <FontAwesomeIcon
                    style={{ color: "#df2425",
                      marginRight: "4px",
                     }}
                    icon={faShoppingCart}
                  />
                  {cartItemCount > 0 && (
                    <span className="cart-item-count">{cartItemCount}</span>
                  )}
                </button>
              </li>
            </div>
          </ul>

          <div className={`sidebar-content ${isSidebarOpen ? "open" : ""}`}>
            {/* Contenido de la barra lateral */}
            <ul>
              {isUserAdmin || isUserDev ? (
                <li>
                  <Link
                    className="links-principales"
                    to="/panel"
                    onClick={toggleSidebar}
                  >
                    <FontAwesomeIcon style={{ color: "#fff" }} icon={faHouse} />{" "}
                    Administrar tienda
                  </Link>
                </li>
              ) : undefined}

              {!isUserWorker && (
                <li>
                  <Link
                    className="links-principales"
                    to="/"
                    onClick={toggleSidebar}
                  >
                    <FontAwesomeIcon style={{ color: "#fff" }} icon={faGlobe} />{" "}
                    Tienda Online
                  </Link>
                </li>
              )}

              {authenticatedUser && (
                <>
                  {" "}
                  {!isUserWorker && (
                    <li>
                      <Link
                        to={authenticatedUser ? "/mis-pedidos" : "/"}
                        className="links-principales"
                        onClick={() => {
                          if (cartOpen) {
                            toggleCart();
                          }
                          if (showPopUp) {
                            setShowPopUp();
                          }
                          if (isSidebarOpen) {
                            toggleSidebar();
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faHistory}
                        />{" "}
                        Historial de compras
                      </Link>
                    </li>
                  )}
                  {!isUserWorker && (
                    <li>
                      <a
                        className="links-principales"
                        onClick={() => {
                          if (cartOpen) {
                            toggleCart();
                          }

                          if (showSignUpForm) {
                            setShowSignUpForm(false);
                          }
                          if (showPopUp) {
                            setShowPopUp();
                          }
                          if (isSidebarOpen) {
                            toggleSidebar();
                          }
                          toggleMostros();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faStar}
                        />{" "}
                        Puntos Mostros
                      </a>
                    </li>
                  )}
                </>
              )}

              {isUserAdmin || isUserDev ? (
                <>
                  <li>
                    <Link
                      className="links-principales"
                      to="/registro-de-pedidos"
                      onClick={toggleSidebar}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faList}
                      />{" "}
                      Pedidos
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/administrar-productos"
                      onClick={() => {
                        if (cartOpen) {
                          toggleCart();
                        }
                        if (userMenuOpen) {
                          toggleUserMenu();
                        }
                        if (isSidebarOpen) {
                          toggleSidebar();
                        }
                        hideOpciones();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faStore}
                      />{" "}
                      Productos
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/administrar-usuarios"
                      onClick={() => {
                        toggleSidebar();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faUsers}
                      />{" "}
                      Usuarios
                    </Link>
                  </li>
                </>
              ) : undefined}

              {isUserWorker && (
                <>
                  <li>
                    <Link
                      className="links-principales"
                      to="/registro-de-pedidos"
                      onClick={toggleSidebar}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faList}
                      />{" "}
                      Pedidos
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/administrar-productos"
                      onClick={() => {
                        if (cartOpen) {
                          toggleCart();
                        }
                        if (userMenuOpen) {
                          toggleUserMenu();
                        }
                        if (isSidebarOpen) {
                          toggleSidebar();
                        }
                        hideOpciones();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faStore}
                      />{" "}
                      Productos
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="links-principales"
                      to="/panel"
                      onClick={toggleSidebar}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faHouse}
                      />{" "}
                      Administrar Tienda
                    </Link>
                  </li>
                </>
              )}

              <li id="redes-sociales">
                <a
                  href={"https://instagram.com/mostrosburgers/"}
                  id="ig"
                  className="social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon className="social-icon" icon={faInstagram} />{" "}
                </a>
                <WhatsAppButton></WhatsAppButton>
              </li>
            </ul>
            <div
              className="toggleSidebar"
              onClick={() => {
                toggleSidebar();
              }}
            ></div>
          </div>

          {userMenuOpen && <UserMenu />}

          {cartOpen && (
            <Carrito
              carrito={carrito}
              eliminarDelCarrito={eliminarDelCarrito}
              modificarCantidad={modificarCantidad}
              modificarCantidadMenu={modificarCantidadMenu}
              cartOpen={cartOpen}
              toggleCart={toggleCart}
              menuItems={productos}
              eliminarMenu={eliminarMenu}
              agregarMenu={agregarMenu}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              handleItemSelectedChange={handleItemSelectedChange}
              calcularSubtotal={calcularSubtotal}
              calcularTotal={calcularTotal}
              vaciarCarrito={vaciarCarrito}
              handleAplicarDescuento={handleAplicarDescuento}
            />
          )}

          {cartOpen && <div className="overlay" onClick={closeCart}></div>}
        </header>
      }

      {!authenticatedUser && showLoginForm && <SignInForm />}
      {!authenticatedUser && showSignUpForm && <SignUpForm />}
    </>
  );
};

export default Header;
