import React, { useEffect, useState } from "react";
import axios from "axios";
import { useShoppingContext } from "../../../context/ShoppingContext";
import { useIsUserAdmin, useIsUserDev } from "../../../context/AuthContext";

const CategoryTable = () => {
  const { categorias, setCategorias, obtenerCategorias, agregarCategoria, nombre, setNombre} = useShoppingContext();
  const [error, setError] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const isUserDev = useIsUserDev();
  const isUserAdmin = useIsUserAdmin();

  const [detallesPorPedido, setDetallesPorPedido] = useState({});

  useEffect(() => {
    // Cargar categorías al montar el componente
    obtenerCategorias();
  }, []);

  

  const handleChangeActivo = async (categoria_id, newValue) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/categorias/${categoria_id}`,
        { activo: newValue === "true" }
      );
      // Si la solicitud se completó correctamente, actualiza el estado de la categoría
      if (response.status === 200) {
        // Clona el array de categorías
        const newCategories = [...categorias];
        // Encuentra la categoría en el array
        const categoryIndex = newCategories.findIndex(
          (category) => category.categoria_id === categoria_id
        );
        // Actualiza el valor activo de la categoría encontrada
        newCategories[categoryIndex].activo = newValue === "true";
        // Actualiza el estado con el nuevo array de categorías
        setCategorias(newCategories);
      }
    } catch (error) {
      console.error(
        "Error al actualizar el estado activo de la categoría:",
        error
      );
    }
  };

  const eliminarCategoria = async (categoria_id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/categorias/${categoria_id}`
      );
      // Filtra las categorías para quitar la categoría eliminada
      const updatedCategories = categorias.filter(
        (categoria) => categoria.categoria_id !== categoria_id
      );
      // Actualiza el estado con las categorías actualizadas
      setCategorias(updatedCategories);
    } catch (error) {
      console.error("Error al eliminar la categoría:", error);
    }
  };

  const editarCategoria = (categoria_id) => {
    // Busca la categoría correspondiente al ID
    const categoryToEdit = categorias.find(
      (categoria) => categoria.categoria_id === categoria_id
    );
    // Actualiza el estado editingCategory con la categoría a editar
    setEditingCategory(categoryToEdit);
  };

 

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/categorias/${editingCategory.categoria_id}`,
        { nombre: editingCategory.nombre }
      );
      console.log("Categoría actualizada:", response.data);
      // Reinicia el estado editingCategory
      setEditingCategory(null);
      // Recarga la lista de categorías
      obtenerCategorias();
    } catch (error) {
      console.error("Error al actualizar la categoría:", error);
    }
  };

  const handleCancelar = () => {
    setEditingCategory(null); // Reinicia el extra que se está editando
  };

  return (
    <div>
      <table className="extra-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>¿Mostrar?</th>
            {isUserDev || isUserAdmin ? <th>Acciones</th> : ""}
          </tr>
        </thead>
        <tbody>
          {categorias.map((categoria) => (
            <React.Fragment key={categoria.categoria_id}>
              <tr>
                <td>{categoria.nombre}</td>
                <td>
                  <select
                    value={categoria.activo ? "true" : "false"}
                    onChange={(e) =>
                      handleChangeActivo(categoria.categoria_id, e.target.value)
                    }
                  >
                    <option value="true">Sí</option>
                    <option value="false">No</option>
                  </select>
                </td>
                {isUserDev || isUserAdmin ? (
                  <td>
                    <button
                      onClick={() => editarCategoria(categoria.categoria_id)}
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => eliminarCategoria(categoria.categoria_id)}
                    >
                      Eliminar
                    </button>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <td></td>
            <td>
              <input
                type="text"
                value={nombre}
                placeholder="Nueva Categoría"
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </td>
            <td>
              <button type="submit" onClick={agregarCategoria}>
                Agregar
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Formulario de edición de categoría */}
      {editingCategory && (
        <div className="extras-container">
          <div className="extras-content">
            <h2>Editar Categoría</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>
                  Nombre:
                  <input
                    type="text"
                    value={editingCategory.nombre}
                    onChange={(e) =>
                      setEditingCategory({
                        ...editingCategory,
                        nombre: e.target.value,
                      })
                    }
                  />
                </label>
              </div>
              <div className="form-actions">
                <button
                  type="button"
                  id="extra-cancel"
                  onClick={handleCancelar}
                >
                  Cancelar
                </button>
                <button type="submit">Guardar Cambios</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryTable;
