import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Details from "../../components/Details/Details";
import "../../styles.css";
import "./Checkout.css";
import { useAuth } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useShoppingContext } from "../../context/ShoppingContext";

const CheckoutShipping = () => {
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [interior, setInterior] = useState("");
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);
  const { authenticatedUser } = useAuth();

  const {
    detailsVisible,
    setDetailsVisible,
    isRotated,
    setRotated,
    showLoginSection,
    setShowLoginSection,
    toggleCart,
    cartOpen,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
    distrito,
    setDistrito,
    calcularEnvio,
    delivery,
    setDelivery,
    costoEnvio,
    setCostoEnvio,
  } = useShoppingContext();

  const handleDeliverySelection = (isDelivery) => {
    setDelivery(isDelivery);
    setCostoEnvio(0);
    if (distrito === "Recojo en tienda") {
      setDistrito("");

      setDireccion("");
    }
    if (!isDelivery) {
      // Si se selecciona "Recojo en tienda", establece el costo de envío en 0 y reinicia la dirección y el distrito
      setCostoEnvio(0);
      setDireccion("");
      setDistrito("Recojo en tienda");
    }
  };

  useEffect(() => {
    if (authenticatedUser) {
      setNombre(authenticatedUser?.nombre || "");
      setApellidos(authenticatedUser?.apellido || "");
      setEmail(authenticatedUser?.email || "");
      setTelefono(authenticatedUser?.telefono || "");
      setDireccion(authenticatedUser?.direccion || "");
      setDistrito(authenticatedUser?.distrito || "");
      setCostoEnvio(calcularEnvio(authenticatedUser?.distrito) || 0);
    } else {
      setNombre("");
      setApellidos("");
      setEmail("");
      setTelefono("");
      setDireccion("");
      setDistrito("");
      setCostoEnvio(0);
    }

    // Recuperar los datos de envío del localStorage al cargar el componente
    const savedShippingInfo = localStorage.getItem("shippingInfo");
    if (savedShippingInfo) {
      const parsedShippingInfo = JSON.parse(savedShippingInfo);
      setEmail(parsedShippingInfo.email);
      setNombre(parsedShippingInfo.nombre);
      setApellidos(parsedShippingInfo.apellidos);
      setTelefono(parsedShippingInfo.telefono);
      setDireccion(parsedShippingInfo.direccion);
      setDistrito(parsedShippingInfo.distrito);
      setCostoEnvio(parsedShippingInfo.costoEnvio);
    }
  }, [authenticatedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (delivery) {
      if (direccion && distrito) {
        // Verificar si el distrito está en la lista de opciones
        const isDistritoValid = Array.from(
          document.getElementById("distrito-list").options
        )
          .map((option) => option.value)
          .includes(distrito);

        if (isDistritoValid) {
          // Si el distrito es válido, continuar con el envío
          const shippingInfo = {
            email,
            direccion,
            distrito,
            nombre,
            apellidos,
            telefono,
            costoEnvio,
          };
          localStorage.setItem("shippingInfo", JSON.stringify(shippingInfo));
          navigate("/checkout", { state: { shippingInfo } });
        } else {
          // Si el distrito no es válido, mostrar un mensaje de error
          alert(
            `Lo sentimos, no llegamos al distrito ${distrito} o el distrito está mal escrito.`
          );
        }
      } else {
        alert("Por favor, complete la dirección y el distrito.");
      }
    } else {
      navigate("/checkout");
    }
  };

  const handleRecojoEnTienda = (e) => {
    e.preventDefault();
    if (!delivery) {
      // Si la opción seleccionada es "Delivery", realizar validación adicional

      // Realizar el envío si se proporciona la dirección y el distrito
      const shippingInfo = {
        email,
        distrito: "Recojo en tienda",
        nombre,
        apellidos,
        telefono,
        costoEnvio,
      };
      localStorage.setItem("shippingInfo", JSON.stringify(shippingInfo));
      navigate("/checkout", { state: { shippingInfo } });
    } else {
      // Si la opción seleccionada es "Recojo en tienda", simplemente proceder al siguiente paso
      navigate("/checkout");
    }
  };

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
    setRotated(!isRotated);
  };

  const handleSkipLogin = () => {
    setShowLoginSection(false);
  };

  return (
    <div className="content-container">
      <div className="content-box">
        <h2 className="heading">Método de envío</h2>
        <div className="delivery-options">
          <div
            className={
              !delivery ? "delivery-option-checked" : "delivery-option"
            }
            checked={!delivery} // Verifica si la opción de recojo está seleccionada
            onClick={() => handleDeliverySelection(false)} // Maneja la selección de recojo
          >
            <span>Recojo en tienda</span>
          </div>
          <div
            className={delivery ? "delivery-option-checked" : "delivery-option"}
            checked={delivery} // Verifica si la opción de delivery está seleccionada
            onClick={() => handleDeliverySelection(true)} // Maneja la selección de delivery
          >
            <span>Delivery</span>
          </div>
        </div>

        <div className="flex-column">
          {!delivery && (
            <>
              <div className="direc-tienda">
                <p>Recoger en:</p>
                <span>Calle Comandante Gustavo Jimenez 580, Magdalena</span>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.323025750362!2d-77.06120968901959!3d-12.090024342658934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9ab04407661%3A0x4a4a3f5a8811da1f!2sC.%20Comandante%20Gustavo%20Jimenez%20580%2C%20Magdalena%20del%20Mar%2015076!5e0!3m2!1ses!2spe!4v1715053351198!5m2!1ses!2spe"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              <Link
                to="/checkout"
                className="next-step-button"
                onClick={handleRecojoEnTienda}
              >
                Siguiente
              </Link>
            </>
          )}
        </div>
        {delivery && (
          <>
            {showLoginSection && !authenticatedUser && (
              <div className="login-section">
                <span>
                  ¿Ya tienes una cuenta?{" "}
                  <a
                    onClick={() => {
                      if (cartOpen) {
                        toggleCart();
                      }

                      if (!showLoginForm) {
                        setShowLoginForm(true);
                      } else {
                        setShowLoginForm(false);
                      }

                      if (showSignUpForm) {
                        setShowSignUpForm(false);
                      }
                    }}
                  >
                    Inicia sesión
                  </a>{" "}
                  o{" "}
                  <a
                    onClick={() => {
                      if (cartOpen) {
                        toggleCart();
                      }

                      if (showLoginForm) {
                        setShowLoginForm(false);
                      }

                      if (!showSignUpForm) {
                        setShowSignUpForm(true);
                      } else {
                        setShowSignUpForm(false);
                      }
                    }}
                  >
                    Regístrate
                  </a>
                  .
                </span>
                <span
                  className="omitir"
                  type="button"
                  onClick={handleSkipLogin}
                >
                  <FontAwesomeIcon icon={faTimes} /> Comprar sin iniciar sesión{" "}
                </span>
              </div>
            )}
            <form className="checkout-form-container" onSubmit={handleSubmit}>
              <div className="form">
                <div className="input-group">
                  <label htmlFor="direccion" className="label">
                    Direccion <span className="color-red-bold">*</span>
                  </label>
                  <input
                    type="text"
                    id="direccion"
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    className="input"
                    required
                  />
                </div>
                <div className="input-group flex-2">
                  <label htmlFor="distrito" className="label">
                    Distrito <span className="color-red-bold">*</span>
                  </label>
                  <div className="custom-select">
                    <input
                      id="distrito"
                      list="distrito-list"
                      value={distrito}
                      onChange={(e) => setDistrito(e.target.value)}
                      className="input"
                      placeholder="Selecciona un distrito"
                      required
                    />
                    <datalist id="distrito-list">
                      <option value="Barranco" />
                      <option value="Jesús María" />
                      <option value="La Molina" />
                      <option value="La Victoria" />
                      <option value="Lince" />
                      <option value="Magdalena" />
                      <option value="Miraflores" />
                      <option value="Pueblo Libre" />
                      <option value="San Borja" />
                      <option value="San Isidro" />
                      <option value="San Luis" />
                      <option value="San Miguel" />
                      <option value="Santiago de Surco" />
                      <option value="Surquillo" />
                    </datalist>
                  </div>
                </div>

<div className="flex">
<input
                  type="submit"
                  value="Siguiente"
                  className="next-step-button"
                />
</div>
                
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutShipping;
