import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Panel.css";
import { Link } from "react-router-dom";
import { useShoppingContext } from "../../../context/ShoppingContext";
import {
  useIsUserAdmin,
  useIsUserDev,
  useIsUserWorker,
} from "../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faStore,
  faStoreSlash,
  faClockRotateLeft,
  faSquareEnvelope,
  faPercent,
  faUsers,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PedidoCard from "../Pedidos/PedidoCard";

const Panel = () => {
  const [nuevoEstado, setNuevoEstado] = useState("");
  const [nuevoEstadoActivo, setNuevoEstadoActivo] = useState("");
  const notification = new Audio("sounds/push.mp3");

  const [lastPedidosCount, setLastPedidosCount] = useState(0);
  const [playedSound, setPlayedSound] = useState(false);
  const {
    open,
    setOpen,
    fetchEstados,
    estados,
    obtenerUsuarios,
    obtenerPedidos,
    obtenerProductos,
    productosOriginales,
    pedidos,
  } = useShoppingContext();
  useEffect(() => {
    fetchEstados();
  }, []);

  const isUserAdmin = useIsUserAdmin();
  const isUserDev = useIsUserDev();

  const newPedidos = pedidos.filter(
    (pedido) => pedido.estado_pedido === "Activo"
  );

  const handleNuevoEstadoChange = (event) => {
    setNuevoEstado(event.target.value);
  };

  const handleNuevoEstadoActivoChange = (event) => {
    setNuevoEstadoActivo(event.target.value);
  };

  const handleSubmitNuevoEstado = async (event) => {
    event.preventDefault();
    try {
      // Convertir el valor de nuevoEstadoActivo a un valor booleano
      const activo = nuevoEstadoActivo === "true";

      // Crea un nuevo estado en la base de datos
      await axios.post(`${process.env.REACT_APP_API_URL}/estados`, {
        nombre: nuevoEstado,
        activo, // Utiliza el valor booleano
      });

      // Limpia el campo del nuevo estado después de crearlo
      setNuevoEstado("");
      setNuevoEstadoActivo("");
      // Vuelve a obtener la lista de estados para reflejar los cambios
      fetchEstados();
    } catch (error) {
      console.error("Error al crear el nuevo estado:", error);
    }
  };

  const handleEliminarEstado = async (estadoId) => {
    try {
      // Envía una solicitud DELETE al servidor para eliminar el estado
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/estados/${estadoId}`
      );

      // Actualiza la lista de estados después de eliminar
      fetchEstados();
    } catch (error) {
      console.error("Error al eliminar el estado:", error);
    }
  };

  const handleTiendaOpen = async () => {
    try {
      // Busca el estado "Abierta" en la lista de estados
      const openEstado = estados.find((estado) => estado.nombre === "Abierta");

      // Busca el estado "Cerrada" en la lista de estados
      const closedEstado = estados.find(
        (estado) => estado.nombre === "Cerrada"
      );

      if (openEstado && closedEstado) {
        // Determina si se va a abrir o cerrar la tienda
        const abrirTienda = !openEstado.activo;

        // Prepara las solicitudes de actualización
        const requests = [
          axios.put(
            `${process.env.REACT_APP_API_URL}/estados/${openEstado.id}`,
            {
              activo: abrirTienda,
            }
          ),
          axios.put(
            `${process.env.REACT_APP_API_URL}/estados/${closedEstado.id}`,
            {
              activo: !abrirTienda,
            }
          ),
        ];

        // Espera a que todas las solicitudes se completen
        await Promise.all(requests);

        // Actualiza el estado en el contexto de compra
        setOpen(abrirTienda);
        // Vuelve a obtener la lista de estados para reflejar los cambios
        fetchEstados();
      }
    } catch (error) {
      console.error("Error al cambiar el estado:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await obtenerPedidos();
        await obtenerProductos();
        await obtenerUsuarios();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    // Establecer temporizador para actualizar los pedidos cada cierto intervalo
    const timerId = setInterval(async () => {
      const newPedidos = await obtenerPedidos(); // Obtener los pedidos

      // Verificar si algún pedido tiene el estado "Activo"
      const hasReceivedPedido = newPedidos.some(
        (pedido) => pedido.estado_pedido === "Activo"
      );

      // Actualizar el estado con la nueva cantidad de pedidos
      setLastPedidosCount(newPedidos.length);
    }, 5000); // Actualizar cada 3 segundos

    // Limpiar el temporizador cuando el componente se desmonte
    return () => clearInterval(timerId);
  }, []); // Dependencia añadida para que el useEffect se ejecute cuando cambie notificationsActive

  return (
    <div className="content-container">
      <div className="dashboard">
        <button
          onClick={handleTiendaOpen}
          className={
            estados.find(
              (estado) => estado.nombre === "Abierta" && estado.activo === true
            )
              ? "tienda-abierta"
              : "tienda-cerrada"
          }
        >
          <span className="tienda-text">
            Tienda{" "}
            {estados.map((estado) => (estado.activo ? estado.nombre : ""))}
          </span>{" "}
          <FontAwesomeIcon
            icon={
              estados.find(
                (estado) =>
                  estado.nombre === "Abierta" && estado.activo === true
              )
                ? faStore
                : faStoreSlash
            }
          />
        </button>

        {newPedidos.length > 0 && (
          <div className="new-pedidos">
            <h2>Nuevos pedidos</h2>
            {newPedidos.map((pedido) => (
              <PedidoCard key={pedido.id} pedido={pedido} />
            ))}
          </div>
        )}
      </div>
      {isUserDev && (
        <form onSubmit={handleSubmitNuevoEstado}>
          <input
            type="text"
            value={nuevoEstado}
            onChange={handleNuevoEstadoChange}
            placeholder="Nuevo estado"
            required
          />
          <input
            type="text"
            value={nuevoEstadoActivo}
            onChange={handleNuevoEstadoActivoChange}
            placeholder="true"
            required
          />
          <button type="submit">Crear estado</button>
        </form>
      )}
      {isUserDev && (
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Activo</th>
            </tr>
          </thead>
          <tbody>
            {estados.map((estado) => (
              <tr key={estado.id}>
                <td>{estado.nombre}</td>
                <td>{estado.activo === true ? "True" : "False"}</td>
                {isUserDev && (
                  <td>
                    <button onClick={() => handleEliminarEstado(estado.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="rectangle-flex-container">
        <div className="rectangle pedidos">
          <div className="faIcon">
            <Link className="faIcon" to="/registro-de-pedidos">
              <FontAwesomeIcon className="pedidos" icon={faSquareEnvelope} />{" "}
            </Link>
          </div>
          <div className="icon-title">Pedidos</div>
        </div>
        <div className="rectangle administrar">
          <div className="faIcon">
            <Link className="faIcon" to="/administrar-productos">
              <FontAwesomeIcon className="administrar" icon={faStore} />{" "}
            </Link>
          </div>
          <div className="icon-title">Productos</div>
        </div>
      </div>
      <div className="rectangles-container">
        {isUserAdmin || isUserDev ? (
          <div className="rectangle-flex-container clientes-pedidos">
            <div className="rectangle mis-pedidos">
              <div className="faIcon">
                <Link className="faIcon" to="/codigos-descuento">
                  <FontAwesomeIcon className="tienda" icon={faPercent} />{" "}
                </Link>
              </div>
              <div className="icon-title">Códigos descuento</div>
            </div>
            <div className="rectangle clientes">
              <div className="faIcon">
                <Link className="faIcon" to="/administrar-usuarios">
                  <FontAwesomeIcon className="mis-pedidos" icon={faUsers} />{" "}
                </Link>
              </div>
              <div className="icon-title">Usuarios</div>
            </div>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default Panel;
