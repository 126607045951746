import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCross,
  faHeadset,
  faStar,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "./UsuariosRecomendados.css";
import { useAuth } from "../../../../context/AuthContext";
import { useShoppingContext } from "../../../../context/ShoppingContext";
import axios from "axios";

const UsuariosRecomendados = () => {
  const { authenticatedUser } = useAuth();
  const { showRecomendados, toggleRecomendados } = useShoppingContext();
  const [recomendados, setRecomendados] = useState([]);

  useEffect(() => {
    if (authenticatedUser) {
      obtenerUsuariosRecomendados();
    }
  }, [authenticatedUser]);

  const obtenerUsuariosRecomendados = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/recomendados/${authenticatedUser.username}`
      );
      setRecomendados(response.data);
    } catch (error) {
      console.error("Error al obtener usuarios recomendados:", error);
    }
  };

  return (
    <div className="recomendados-container">
      <div className="recomendados">
        <ul className="user-menu-flex">
          {authenticatedUser && (
            <div className="info-container">
              <div
                className="userPoints-container"
              >
                <div className="circle-container-star">
                  <FontAwesomeIcon className="star-icon" icon={faUsers} />
                </div>
                <span className="userPoints">
                  {authenticatedUser.recomendados}{" "}
                  {authenticatedUser.recomendados === 1
                    ? "usuario recomendado"
                    : "usuarios recomendados"}
                </span>
              </div>
              <div className="circle-container-star">
                <FontAwesomeIcon
                  className="star-icon"
                  icon={faClose}
                  onClick={toggleRecomendados}
                />
              </div>
            </div>
          )}

          <li className="recomendados-list">
            <table className="recomendados-table">
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Nombre</th>
                  <th>Puntos</th>
                </tr>
              </thead>
              <tbody>
                {recomendados.map((usuario) => (
                  <tr key={usuario.id}>
                    <td>{usuario.username}</td>
                    <td>
                      {usuario.nombre} {usuario.apellido}
                    </td>
                    <td>{usuario.points}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UsuariosRecomendados;
