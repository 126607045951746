// MenuCategory.js
import React, { useState, useEffect } from "react";
import { useShoppingContext } from "../../../context/ShoppingContext";
import "../../../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faClose } from "@fortawesome/free-solid-svg-icons";

const MenuCategory = ({
  category,
  category_id,
  menuItems,
  agregarAlCarrito,
  menuCount,
}) => {
  const {
    toggleCart,
    cartOpen,
    agregarMenu,
    carrito,
    modificarCantidad,
    cargarExtras,
    extras,
    selectedExtras,
    setSelectedExtras,
    carritoExtras,
    setCarritoExtras,
  } = useShoppingContext();
  const [productCounts, setProductCounts] = useState({}); // Estado para extras seleccionados
  const [showExtras, setShowExtras] = useState(false);
  const [selectedMenuItemId, setSelectedMenuItemId] = useState(null);

  // Obtener la cantidad de cada producto en el carrito
  useEffect(() => {
    const counts = {};
    carrito.forEach((item) => {
      counts[item.producto_id] = item.cantidad;
    });
    setProductCounts(counts);
  }, [carrito]);

  // Cargar los extras al montar el componente
  useEffect(() => {
    cargarExtras();
  }, []);

  const handleShowExtrasClick = (menuItem) => {
    setSelectedMenuItemId(menuItem.producto_id);
    setShowExtras(true);
  };

  const handleAgregarClick = (menuItem) => {
    // Filtrar los extras seleccionados y agregar al carrito sin extras
    const extrasSelected = Object.values(
      selectedExtras[menuItem.producto_id] || {}
    ).filter((extra) => extra.cantidad > 0);

    // Add the product to the cart with extras if it's in the "Hamburguesas" category
    if (menuItem.categoria === "Hamburguesas" && extrasSelected.length > 0) {
      // Update carritoExtras with the selected extras for this product
      setCarritoExtras((prevCarritoExtras) => ({
        ...prevCarritoExtras,
        [menuItem.producto_id]: extrasSelected,
      }));
    }

    // Add the product to the cart without extras
    agregarAlCarrito({
      ...menuItem,
      extras: extrasSelected,
    });

    // Reset the selected extras
    setSelectedExtras((prevSelectedExtras) => ({
      ...prevSelectedExtras,
      [menuItem.producto_id]: {}, // Reset the selected extras for this product
    }));

    setShowExtras(false);
    // Reset the extras state in the context
    cargarExtras();
  };

  // Función para restar la cantidad del producto en el carrito
  const handleRestarClick = (menuItem) => {
    const currentCount = productCounts[menuItem.producto_id] || 0;
    if (currentCount > 0) {
      modificarCantidad(menuItem.producto_id, -1);
    }
  };

  // Función para manejar el clic en el botón de agregar extra
  const handleAgregarExtraClick = (extra, productId) => {
    // Verificar si el extra ya está seleccionado para este producto
    const selectedCount = selectedExtras[productId]?.[extra.id]?.cantidad || 0;
    const updatedExtras = {
      ...selectedExtras[productId],
      [extra.id]: { ...extra, cantidad: selectedCount + 1 },
    };
    setSelectedExtras({
      ...selectedExtras,
      [productId]: updatedExtras,
    });
    setCarritoExtras({
      ...selectedExtras,
      [productId]: updatedExtras,
    });
  };

  // Función para manejar el clic en el botón de quitar extra
  const handleQuitarExtraClick = (extra, productId) => {
    const selectedCount = selectedExtras[productId]?.[extra.id]?.cantidad || 0;
    if (selectedCount > 0) {
      const updatedExtras = {
        ...selectedExtras[productId],
        [extra.id]: { ...extra, cantidad: selectedCount - 1 },
      };
      setSelectedExtras({
        ...selectedExtras,
        [productId]: updatedExtras,
      });
    }
  };

  // Función para ocultar la ventana de extras cuando se hace clic en el botón de cierre
  const handleCloseExtrasClick = () => {
    setShowExtras(false);
    setSelectedMenuItemId(null);
  };

  useEffect(() => {
    if (showExtras) {
      // Bloquear el scroll de la página cuando showExtras es true
      document.body.style.overflow = "hidden";
    } else {
      // Habilitar el scroll de la página cuando showExtras es false
      document.body.style.overflow = "auto";
    }

    // Limpiar el efecto cuando el componente se desmonta
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showExtras]);

  return (
    <div className="category-container">
      <div className="menu-category">
        <h2>{category === "Hamburguesas" ? "BURGERS" : category === "Bebidas" ? "BEBIDAS" : category === "Guarnición" ? "GUARNICIÓN": category}</h2>
        <ul className="menu-container">
          {menuItems
            .filter((menuItem) => menuItem.categoria_id === category_id) // Filtrar por la categoría actual
            .sort((a, b) => a.precio - b.precio) // Ordenar por precio de menor a mayor dentro de la categoría
            .filter((menuItem) => menuItem.activo === true) // Filtrar solo los activos
            .map((menuItem) => (
              <li className="menu-item" key={menuItem.producto_id}>
                <div className="menu-item-content">
                  <div className="description">
                    <h3>{menuItem.nombre}</h3>
                    <div className="details">
                      <p>{menuItem.descripcion}</p>
                      <span className="price">
                        Precio: S/. {menuItem.precio.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="image">
                    <img src={menuItem.img} alt={menuItem.nombre} />
                  </div>
                </div>

                <div className="agregar-container">
                  {category === "Hamburguesas" ? (
                    <button
                      className="agregar-button"
                      onClick={() => handleShowExtrasClick(menuItem)}
                    >
                      Agregar
                    </button>
                  ) : (
                    <div className="quantity-buttons">
                      {productCounts[menuItem.producto_id] > 0 && (
                        <button
                          className="quantity-button"
                          onClick={
                            () => handleRestarClick(menuItem) // Utiliza la función para restar
                          }
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                      )}
                      <button
                        className="agregar-button"
                        onClick={() => handleAgregarClick(menuItem)}
                      >
                        {productCounts[menuItem.producto_id] > 0 ? (
                          <span>{productCounts[menuItem.producto_id]}</span>
                        ) : (
                          "Agregar"
                        )}
                      </button>
                      {productCounts[menuItem.producto_id] > 0 && (
                        <button
                          className="quantity-button"
                          onClick={() => handleAgregarClick(menuItem)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      )}
                    </div>
                  )}

                  {showExtras &&
                  selectedMenuItemId === menuItem.producto_id &&
                  category === "Hamburguesas" ? (
                    // Mostrar los extras solo si la categoría es "Hamburguesas"
                    <div className="extras-container">
                      <div className="extras-content">
                        <div>
                          <div className="close-container">
                            <FontAwesomeIcon
                              onClick={handleCloseExtrasClick}
                              className="close-btn"
                              icon={faClose}
                            />
                          </div>
                          <div className="burger-data">
                            <img src={menuItem.img} />
                            <div>
                              <h4>{menuItem.nombre}</h4>
                              <p className="description">
                                {menuItem.descripcion}
                              </p>
                              <h4>S/. {menuItem.precio.toFixed(2)}</h4>
                            </div>
                          </div>
                        </div>
                        <h4 className="extra-title">
                          ¿Deseas agregar un extra a tu hamburguesa?
                        </h4>
                        <ul>
                          {extras.map((extra) => (
                            <li key={extra.id}>
                              <div className="extra-container">
                                <div className="extra-details">
                                  <p>
                                    {extra.nombre} (+ S/. {extra.precio.toFixed(2)})
                                  </p>
                                  <div className="quantity-c">
                                    <button
                                      className="quantity-btn"
                                      onClick={() =>
                                        handleQuitarExtraClick(
                                          extra,
                                          menuItem.producto_id
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                    <p>
                                      {selectedExtras[menuItem.producto_id]?.[
                                        extra.id
                                      ]?.cantidad || 0}
                                    </p>
                                    <button
                                      className="quantity-btn"
                                      onClick={() =>
                                        handleAgregarExtraClick(
                                          extra,
                                          menuItem.producto_id
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <button
                          className="carrito-button carrito-button-pedido"
                          onClick={() => {
                            handleAgregarClick(menuItem);
                            if (!cartOpen) toggleCart(); // Llama a toggleCart si el carrito no está abierto
                          }}
                        >
                          <div className="flex-2">Agregar al carrito </div>
                          <div>
                            S/.{" "}
                            {(
                              menuItem.precio +
                              Object.values(
                                selectedExtras[menuItem.producto_id] || {}
                              ).reduce(
                                (total, extra) =>
                                  total + extra.precio * extra.cantidad,
                                0
                              )
                            ).toFixed(2)}
                          </div>
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuCategory;
