// Carrito.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./carrito.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faPlus,
  faMinus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useShoppingContext } from "../../context/ShoppingContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./slick-theme.css";
import _ from 'lodash';

const Carrito = ({
  carrito,
  eliminarDelCarrito,
  modificarCantidad,
  toggleCart,
  cartOpen,
  handleAplicarDescuento,
}) => {
  const navigate = useNavigate();
  const {
    productos,
    agregarAlCarrito,
    cartItemCount,
    estados,
    fetchEstados,
    modificarCantidadHamburguesa,
    eliminarHamburguesa,
  } = useShoppingContext();

  const handleModificarCantidad = (itemId, cantidad) => {
    modificarCantidad(itemId, cantidad);
    handleAplicarDescuento();
  };

  // Function to apply discount when an item is added to the cart
  const handleAgregarAlCarrito = (producto) => {
    agregarAlCarrito(producto);
      handleAplicarDescuento();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      debouncedFetchEstados();
    }, 3000);

    return () => clearInterval(interval);
  }, []);


  const openEstado = estados.find((estado) => estado.nombre === "Abierta");

  // Debounce the fetchEstados function with a 3-second delay
  const debouncedFetchEstados = _.debounce(fetchEstados, 3000);

  useEffect(() => {
    const interval = setInterval(() => {
      debouncedFetchEstados();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const calcularSubtotal = () => {
    let subtotal = 0;

    // Verificar si carrito tiene un valor antes de iterar sobre él
    if (carrito) {
      // Sumar el precio total de cada producto en el carrito
      carrito.forEach((item) => {
        subtotal += calcularPrecioTotal(item) * item.cantidad;
      });
    }

    return subtotal;
  };

  const calcularPrecioTotal = (producto) => {
    let precioTotal = producto.precio;

    // Verificar si producto.extras está definido antes de iterar sobre él
    if (producto.extras) {
      // Sumar el precio de cada extra al precio total
      producto.extras.forEach((extra) => {
        precioTotal += extra.precio * extra.cantidad;
      });
    }

    return precioTotal;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // Desplazamiento automático
    autoplaySpeed: 5000, // Cada 5 segundos
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // Determinar las configuraciones del carrusel
  let carouselSettings = { ...settings }; // Copiar las configuraciones existentes

  // Verificar si solo queda un elemento en el carrusel
  if (productos.length < 2) {
    // Actualizar las configuraciones para mostrar solo 1 elemento
    carouselSettings = {
      ...carouselSettings,
      slidesToShow: 2,
      slidesToScroll: 0,
    };
  }

  return (
    <>
      <div className="cart-container" onClick={toggleCart}></div>
      <div className="close-cart" onClick={toggleCart}></div>
      <div className={`carrito-sidebar ${cartOpen ? "open" : ""}`}>
        <div className="carrito-close-button" onClick={toggleCart}>
          <FontAwesomeIcon icon={faTimes} className="close-icon" />
        </div>
        <div className="carrito-container">
          {carrito.length === 0 ? (
            <div className="empty-cart">
              <p>No has seleccionado ningún producto.</p>
              <Link
                to="/menu"
                className="continue-shopping"
                onClick={toggleCart}
              >
                Ir a comprar
              </Link>
            </div>
          ) : (
            <>
              <h2 className="carrito-title">TU CARRITO</h2>
              <ul className="carrito-items">
                {carrito.map((item, index) => (
                  <li
                    className="carrito-item"
                    key={`${item.producto_id}-${index}`}
                  >
                    <div className="image-product-price">
                      <img
                        className="carrito-img"
                        alt="product image"
                        src={item.img}
                      ></img>
                      <div className="product-price">
                        <h4>
                          {item.cantidad < 10 ? "0" : ""}
                          {item.cantidad} x {item.nombre}
                        </h4>
                        {item.extras && item.extras.length > 0 && (
                          // Mostrar extras
                          <div>
                            <h3>Extras:</h3>
                            <ul>
                              {item.extras.map((extra, index) => (
                                <li key={`${extra.id}-${index}`}>
                                  <p>
                                    {extra.nombre} (+ S/.{" "}
                                    {extra.precio.toFixed(2)}) x{" "}
                                    {extra.cantidad}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="cantidad-precio">
                          <p>S/. {calcularPrecioTotal(item).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>

                    <div className="quantity-trash">
                      <div
                        className="trash"
                        onClick={() => {
                          if (item.categoria_id !== 1) {
                            eliminarDelCarrito(item.producto_id);
                          } else if (item.categoria_id === 1) {
                            eliminarHamburguesa(index);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>

                      <div className="quantity-cell">
                        <div
                          className="quantity-button"
                          onClick={() => {
                            if (item.categoria_id !== 1) {
                              handleModificarCantidad(
                                item.producto_id,
                                -1,
                                item.id_categoria
                              );
                            } else if (item.categoria_id === 1) {
                              modificarCantidadHamburguesa(index, -1);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </div>
                        <span className="item-quantity">{item.cantidad}</span>
                        <div
                          className="quantity-button"
                          onClick={() => {
                            if (item.categoria_id !== 1) {
                              handleModificarCantidad(
                                item.producto_id,
                                1,
                                item.categoria_id
                              );
                            } else if (item.categoria_id === 1) {
                              modificarCantidadHamburguesa(index, 1);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
                <div className="subtotal">
                  <p>Costo de los productos</p>
                  <p>S/. {calcularSubtotal().toFixed(2)}</p>
                </div>
              </ul>
              <div className="section__carousel-recommended">
                <h3>También agrega:</h3>
                <Slider {...settings}>
                  {productos
                    .filter(
                      (producto) =>
                        producto.categoria_id !== 1 && // Excluir productos con categoría 1
                        (producto.categoria_id === 3 ||
                          producto.categoria_id === 2 ||
                          producto.categoria !== "Hamburguesas")
                    ) // Filtrar por categoría "Bebidas" (id 3) y "Guarnición" (id 2) y excluyendo las "Hamburguesas"
                    .sort((a, b) => {
                      // Ordenar los productos para que los de categoría 3 aparezcan primero
                      if (a.categoria_id === 3 && b.categoria_id !== 3) {
                        return -1; // a viene antes que b
                      } else if (a.categoria_id !== 3 && b.categoria_id === 3) {
                        return 1; // b viene antes que a
                      } else {
                        return 0; // No se cambia el orden
                      }
                    })
                    .map((producto, index) => {
                      // Verificar si el producto ya está en el carrito
                      const enCarrito = carrito.some(
                        (item) => item.producto_id === producto.producto_id
                      );

                      // Renderizar el producto solo si no está en el carrito
                      if (!enCarrito) {
                        return (
                          <div key={index} className="carousel-product">
                            <img
                              src={producto.img}
                              width={150}
                              alt={producto.nombre}
                            />
                            <h4>{producto.nombre}</h4>
                            <p>Precio: S/. {producto.precio}</p>
                            <button
                              className="carrito-button"
                              onClick={() => handleAgregarAlCarrito(producto)}
                            >
                              Agregar
                            </button>
                          </div>
                        );
                      } else {
                        return null; // No renderizar el producto si ya está en el carrito
                      }
                    })}
                </Slider>
              </div>

              {openEstado?.activo === true ? (
                <div className="carrito-options-fixed">
                  <div className="opciones-carrito">
                    <button
                      className="carrito-button carrito-button-pedido"
                      onClick={() => {
                        toggleCart();
                        navigate("/checkout/shipping");
                      }}
                    >
                      <p className="item-count">{cartItemCount}</p>
                      <p>IR A PAGAR</p>
                      <p>S/. {calcularSubtotal().toFixed(2)}</p>
                    </button>
                    <button
                      className="carrito-button seguir-comprando"
                      onClick={() => {
                        toggleCart();
                        navigate("/");
                      }}
                    >
                      SEGUIR COMPRANDO
                    </button>
                  </div>
                </div>
              ) : (
                <div className="carrito-options-fixed">
                  <div className="opciones-carrito">
                    <h2>
                      Lo sentimos, la tienda está cerrada. Regresa mañana.
                    </h2>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Carrito;
