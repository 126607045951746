import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCross,
  faHeadset,
  faStar,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "./Clasificacion.css";
import { useAuth } from "../../../../context/AuthContext";
import { useShoppingContext } from "../../../../context/ShoppingContext";
import axios from "axios";

const Clasificacion = () => {
  const { authenticatedUser } = useAuth();
  const { toggleMostros } = useShoppingContext();
  const [usuarios, setUsuarios] = useState([]);
  const [authenticatedUserPosition, setAuthenticatedUserPosition] =
    useState(null);

  useEffect(() => {
    if (authenticatedUser) {
      obtenerUsuarios();
    }
  }, [authenticatedUser]);

  const obtenerUsuarios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      // Ordenar la lista de usuarios por puntos de forma descendente
      const usuariosOrdenados = response.data.sort(
        (a, b) => b.points - a.points
      );
      // Tomar solo los primeros 5 usuarios
      const top5Usuarios = usuariosOrdenados.slice(0, 5);

      // Verificar si el authenticatedUser está dentro del top 5
      const authenticatedUserIndex = top5Usuarios.findIndex(
        (user) => user.username === authenticatedUser.username
      );

      if (authenticatedUserIndex === -1) {
        // Si el authenticatedUser no está en el top 5, añadirlo al final de la lista
        const allUsers = response.data;
        allUsers.push(authenticatedUser);

        // Ordenar nuevamente la lista completa
        const allUsersSorted = allUsers.sort((a, b) => b.points - a.points);

        // Actualizar la posición del authenticatedUser en la lista completa
        const authenticatedUserPositionAll = allUsersSorted.findIndex(
          (user) => user.username === authenticatedUser.username
        );
        setAuthenticatedUserPosition(authenticatedUserPositionAll + 1);

        // Tomar solo los primeros 5 usuarios
        const top5UsuariosUpdated = allUsersSorted.slice(0, 5);
        setUsuarios(top5UsuariosUpdated);
      } else {
        // Si el authenticatedUser está en el top 5, simplemente establecer la lista
        setUsuarios(top5Usuarios);
      }
    } catch (error) {
      console.error("Error al obtener usuarios recomendados:", error);
    }
  };

  return (
    <div className="recomendados-container" onClick={toggleMostros}>
      <div className="recomendados">
        <ul className="user-menu-flex">
          {authenticatedUser && (
            <div className="info-container">
              <div className="userPoints-container">
                <div className="circle-container-star">
                  <FontAwesomeIcon className="star-icon" icon={faStar} />
                </div>
                <span className="userPoints">Top 5</span>
              </div>
              <div className="circle-container-star">
                <FontAwesomeIcon
                  className="star-icon"
                  icon={faClose}
                  onClick={toggleMostros}
                />
              </div>
            </div>
          )}

          <li className="recomendados-list">
            <table className="recomendados-table">
              <thead>
                <tr>
                  <th id="usuario-th">Usuario</th>
                  <th id="puntos-th">Puntos</th>
                </tr>
              </thead>
              <tbody>
                {usuarios.map((usuario, index) => (
                  <tr key={usuario.id} className={authenticatedUser.id === usuario.id ? "myUser top5" : "top5"}>
                    <td className="flex-center">
                      <span className={index < 3 ? "circle-container top3" : "circle-container"}>
                        {index + 1}
                      </span>{" "}
                      {usuario.username}
                    </td>
                    <td>{usuario.points}</td>
                  </tr>
                ))}
                {/* Mostrar al authenticatedUser si no está en el top 5 */}
                {authenticatedUserPosition !== null &&
                  authenticatedUserPosition >= 5 && (
                    <tr className={"authenticated-user-position myUser"}>
                      <td className="flex-center">
                        <span className="circle-container">
                          {authenticatedUserPosition}
                        </span>{" "}
                        {authenticatedUser.username}
                      </td>
                      <td>{authenticatedUser.points}</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Clasificacion;
