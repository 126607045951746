import React, { useState, useEffect } from "react";
import axios from "axios";
import { useShoppingContext } from "../../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCross } from "@fortawesome/free-solid-svg-icons";

const AddProductForm = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    descripcion: "",
    img: "",
    stock: "",
    categoria_id: "",
    precio: "",
    precio_estadio: "",
  });

  const {
    categorias,
    setCategorias,
    obtenerCategorias,
    cargarProductos,
    showElement,
    setShowElement,
    setEditando,
    setProductosOriginales,
    nombre,
    setNombre,
    agregarCategoria,
  } = useShoppingContext();

  const [categoriaNueva, setCategoriaNueva] = useState("");
  const [agregar, setAgregar] = useState(false);
  const [input, setInput] = useState(false);
  const [select, setSelect] = useState(true);
  const [agregarCat, setAgregarCat] = useState(true);

  useEffect(() => {
    obtenerCategorias();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const obtenerProductos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      setProductosOriginales(response.data);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/`,
        formDataToSend,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data", // Establece el tipo de contenido como formulario de datos multipartes
          },
        }
      );

      setFormData({
        nombre: "",
        descripcion: "",
        img: "",
        stock: "",
        categoria_id: "",
        precio: "",
        precio_estadio: "",
      });
      obtenerProductos();
      toggleShow("product");
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  const changeAgregar = () => {
    setAgregarCat(!agregarCat);
    setAgregar(!agregar);
    setSelect(!select);
    setInput(!input);
  };

  const Agregar = (categoria) => {
    changeAgregar();
    agregarCategoria(categoria); // No pases 'event' como argumento aquí
  };

  const toggleShow = (element) => {
    setShowElement((prevElement) => (prevElement === element ? null : element));
  };

  const handleCancelClick = () => {
    toggleShow("product"); // Oculta el componente al hacer clic en Cancelar
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Obtiene el archivo seleccionado
    setFormData({ ...formData, img: file }); // Actualiza el estado con el archivo seleccionado
  };

  const formDataToSend = new FormData(); // Crea un nuevo objeto FormData
  formDataToSend.append("nombre", formData.nombre);
  formDataToSend.append("descripcion", formData.descripcion);
  formDataToSend.append("img", formData.img); // Agrega el archivo de imagen al FormData

  return (
    <div className="extras-container">
      <div className="extras-content">
        <h2>Agregar Producto</h2>

        <form className="producto-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              required
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="descripcion">Descripción</label>
            <textarea
              id="descripcion"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="img">Imagen</label>
            <input
              type="text"
              id="img"
              name="img"
              value={formData.img}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="img">Imagen</label>
            <input
              type="file" // Cambia el tipo de input a "file"
              id="img"
              name="img"
              accept="image/*" // Acepta solo archivos de imagen
              onChange={handleFileChange} // Usa una función de cambio de archivo
            />
          </div>

          <div className="form-group">
            <div className="new-producto-numbers">
              <label htmlFor="stock">Stock</label>
              <input
                required
                type="number"
                id="stock"
                name="stock"
                value={formData.stock}
                onChange={handleChange}
              />
              <label htmlFor="precio">Precio</label>
              <input
                type="number"
                id="precio"
                name="precio"
                value={formData.precio}
                onChange={handleChange}
              />
              {/* <label htmlFor="precio_estadio">Precio tachado:</label>
              <input
                type="number"
                id="precio_estadio"
                name="precio_estadio"
                value={formData.precio_estadio}
                onChange={handleChange}
              /> */}
            </div>
          </div>
          <div className="form-group categoria-group">
            <div className="flex-space-between">
              <label htmlFor="categoria_id">Categoría</label>
              {agregarCat && (
                <p
                  className="agregarCategoria"
                  onClick={changeAgregar}
                >
                  NUEVA CATEGORÍA
                </p>
              )}
              {agregar && (
                <>
                  <div className="agregarCategoria">
                    <p
                      onClick={() => {
                        Agregar(nombre);
                      }}
                    >
                      <FontAwesomeIcon
                        className="close-cat"
                        icon={faClose}
                        onClick={changeAgregar}
                      />
                      Agregar
                    </p>
                  </div>
                </>
              )}
            </div>

            {input && (
              <input
                type="text"
                value={nombre}
                placeholder="Nueva Categoría"
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            )}

            {select && (
              <select
                required
                id="categoria_id"
                name="categoria_id"
                value={formData.categoria_id}
                onChange={handleChange}
              >
                <option value="">Selecciona una categoría</option>
                {categorias.map((categoria) => (
                  <option
                    key={categoria.categoria_id}
                    value={categoria.categoria_id}
                  >
                    {categoria.nombre}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="form-actions">
            <button type="button" onClick={handleCancelClick}>
              Cancelar
            </button>
            <button type="submit">Registrar producto</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProductForm;
