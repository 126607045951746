import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useShoppingContext } from "../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Details.css";

const Details = () => {
  const location = useLocation();
  const {
    carrito,
    setSelectedItems,
    montoTotal,
    setMontoTotal,
    calcularEnvio,
    setCostoEnvio,
    precioFinalConDescuento,
    setPrecioFinalConDescuento,
    descuento,
    setDescuento,
    codigoDescuento,
    setCodigoDescuento,
    setSubtotal,
    costoEnvio,
  } = useShoppingContext();

  const [detalleOpen, setDetalleOpen] = useState(true);
  const [error, setError] = useState("Ingresa tu cupón de descuento");
  const { shippingInfo } = location.state || {};
  const { distrito } = shippingInfo || {};

  useEffect(() => {
    const savedSelectedItems = localStorage.getItem("selectedItems");
    if (savedSelectedItems) {
      setSelectedItems(JSON.parse(savedSelectedItems));
    }
    calcularCostoEnvio();
  }, []);

  useEffect(() => {
    calcularCostoEnvio();
  }, [distrito]);

  useEffect(() => {
    const fetchCodigosDescuento = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/codigos`
        );
        // Hacer algo con los datos si es necesario
      } catch (error) {
        console.error("Error al obtener los códigos de descuento:", error);
        // Manejar el error de acuerdo a tus necesidades
      }
    };
    fetchCodigosDescuento();
  }, []);

  const calcularSubtotal = () => {
    return carrito.reduce((total, item) => {
      const monto_total = calcularPrecioTotal(item);
      return total + monto_total;
    }, 0);
  };

  const calcularPrecioTotal = (item) => {
    let total = item.precio * item.cantidad;
    if (item.extras && item.extras.length > 0) {
      item.extras.forEach((extra) => {
        total += extra.precio * extra.cantidad;
      });
    }
    return total;
  };

  useEffect(() => {
    const total = calcularSubtotal() + costoEnvio;
    setMontoTotal(total);
  }, [calcularSubtotal, costoEnvio]);

  const calcularCostoEnvio = () => {
    const nuevoCostoEnvio = calcularEnvio(distrito);
    setCostoEnvio(nuevoCostoEnvio);
    localStorage.setItem("costoEnvio", nuevoCostoEnvio.toString());
  };

  useEffect(() => {
    // Calcula el descuento cada vez que cambie el carrito
    const descuentoAplicado = calcularDescuento();
    setDescuento(descuentoAplicado);
  }, [carrito]);

  const handleAplicarDescuento = async () => {
    try {
      if (!codigoDescuento) {
        setError("Ingresa un código de descuento");
        return;
      }
  
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/codigos`
      );
      const codigosDescuento = response.data;
  
      const codigoEncontrado = codigosDescuento.find(
        (codigo) => codigo.codigo === codigoDescuento
      );
  
      if (!codigoEncontrado) {
        setError("El código de descuento no existe");
        return;
      }
  
      if (codigoEncontrado.usosActual >= codigoEncontrado.usosMax) {
        setError("El código de descuento ha alcanzado su límite de usos");
        return;
      }
  
      const descuentoAplicado = calcularDescuento(codigoEncontrado);
      setDescuento(descuentoAplicado);
  
      // No incrementar usosActual si es un descuento infinito (-1)
      if (codigoEncontrado.usosMax !== -1) {
        codigoEncontrado.usosActual++;
        await axios.put(
          `${process.env.REACT_APP_API_URL}/codigos/${codigoEncontrado.id}`,
          codigoEncontrado
        );
      }
  
      setMontoTotal(precioFinalConDescuento);
      setError(`Cupón usado: ${codigoEncontrado.codigo}`);
    } catch (error) {
      console.error("Error al aplicar el código de descuento:", error);
      setError(
        "Error al aplicar el código de descuento. No existe o está mal escrito."
      );
    }
  };
  

  const calcularDescuento = (codigoEncontrado) => {
    let descuento = 0;
    if (codigoEncontrado && codigoEncontrado.porcentaje !== undefined) {
      descuento = (codigoEncontrado.porcentaje / 100) * calcularSubtotal();
    } else if (codigoEncontrado && codigoEncontrado.montoFijo !== undefined) {
      descuento = codigoEncontrado.montoFijo;
    }
    return descuento;
  };
  

  const calcularPrecioSinExtra = (item) => {
    let total = item.precio * item.cantidad;

    return total;
  };

  useEffect(() => {
    setPrecioFinalConDescuento(calcularSubtotal() + costoEnvio - descuento);
    setSubtotal(calcularSubtotal());
  }, [calcularSubtotal, costoEnvio, descuento]);

  return (
    <table className="product-table">
      <thead className="pointer" onClick={() => setDetalleOpen(!detalleOpen)}>
        <tr>
          <th style={{ fontSize: "1.3rem", fontWeight: "300" }}>
            Detalle de pedido
          </th>
        </tr>
        <tr>
          <th>
            <FontAwesomeIcon
              icon={!detalleOpen ? faAngleDown : faAngleUp}
              style={{ color: "#fff", fontSize: "1.5rem" }}
            ></FontAwesomeIcon>
          </th>
        </tr>
      </thead>
      {detalleOpen && (
        <tbody>
          <tr className="details-products">
            {carrito.map((item, index) => (
              <td
                className="checkout-products flex flex-1"
                style={{ alignItems: "center" }}
                key={`${item.producto_id}-${index}`}
              >
                <img src={item.img} alt="imagen del producto" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "8px",
                    flex: "2",
                  }}
                >
                  {item.extras && item.extras.length > 0 ? (
                    <>
                      <div className="product-name">
                        <span>
                          {item.cantidad !== 1
                            ? `${item.cantidad} x ${item.nombre} `
                            : `${item.nombre} `}
                        </span>
                        <span className="product-price-details">
                          S/. {calcularPrecioSinExtra(item).toFixed(2)}
                        </span>
                      </div>
                      <div className="details-extras">
                        <h4>Extras:</h4>
                        <ul>
                          {item.extras.map((extra, idx) => (
                            <li key={`${extra.id}-${idx}`}>
                              <div className="extra-cantidad-nombre">
                                {extra.cantidad} x {extra.nombre}
                              </div>
                              <div className="extra-price-details">
                                + (S/.{" "}
                                {(extra.precio * extra.cantidad).toFixed(2)})
                              </div>
                            </li>
                          ))}
                          <span className="precio-total-de-hamburguesa-extras">
                            S/. {calcularPrecioTotal(item).toFixed(2)}
                          </span>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <div>
                      <div className="product-name">
                        <span className="product-name">
                          {item.cantidad !== 1
                            ? `${item.cantidad} x ${item.nombre} `
                            : `${item.nombre} `}
                        </span>
                        <span className="product-price-details">
                          {" "}
                          S/. {calcularPrecioTotal(item).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </td>
            ))}
          </tr>

          <tr className="flex-column descuento-container">
            <td>
              <h1 className="codigo-descuento-title">{error}</h1>
              <div className="input-button-container">
                <input
                  type="text"
                  placeholder="Código"
                  onChange={(e) => setCodigoDescuento(e.target.value)}
                  className="descuento-input"
                />
                <button
                  type="button"
                  className="descuento-button"
                  onClick={handleAplicarDescuento}
                >
                  Aplicar
                </button>
              </div>
            </td>
          </tr>

          <tr className="cart-total">
            <td className="justify">
              <b>Costo de los productos</b>
            </td>
            <td className="price">S/. {calcularSubtotal().toFixed(2)}</td>
          </tr>

          {distrito !== "Recojo en tienda" && (
            <tr className="cart-total">
              <>
                <td className="justify">
                  <b>Costo de envío </b>
                  {shippingInfo && distrito ? <b>({distrito})</b> : ""}
                </td>
                <td className="price">
                  S/.{" "}
                  {shippingInfo && costoEnvio
                    ? calcularEnvio(distrito).toFixed(2)
                    : ""}
                </td>
              </>
            </tr>
          )}

          {descuento !== 0 && (
            <tr className="cart-total">
              <td className="justify">
                <b>Descuento aplicado</b>
              </td>
              <td className="price red-text">- S/. {descuento.toFixed(2)}</td>
            </tr>
          )}

          <tr className="cart-total">
            <td className="justify">
              <b>Total a pagar</b>
            </td>
            {descuento ? (
              <td className="price">
                S/. {precioFinalConDescuento.toFixed(2)}
              </td>
            ) : (
              ""
            )}
            {!descuento && distrito === "Recojo en tienda" && (
              <td className="price">S/. {calcularSubtotal().toFixed(2)}</td>
            )}
            {!descuento && distrito !== "Recojo en tienda" && (
              <td className="price">
                S/. {(calcularSubtotal() + costoEnvio).toFixed(2)}
              </td>
            )}
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default Details;
