import React, { useState, useEffect } from "react";
import ListaProductos from "./ListaProductos";
import "../../../styles.css";
import { useShoppingContext } from "../../../context/ShoppingContext";
import ExtraList from "../Extras/ExtraList";
import CategoryTable from "../Categorias/categoryTable.jsx";
import AddProductForm from "./AgregarProductoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";

const AdminStore = () => {
  const { showElement, setShowElement } = useShoppingContext();

  const toggleShow = (element) => {
    setShowElement((prevElement) => (prevElement === element ? null : element));
  };

  useEffect(() => {
    if (showElement === "product") {
      // Bloquear el scroll de la página cuando showExtras es true
      document.body.style.overflow = "hidden";
    } else {
      // Habilitar el scroll de la página cuando showExtras es false
      document.body.style.overflow = "auto";
    }

    // Limpiar el efecto cuando el componente se desmonta
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showElement]);

  return (
    <div className="content-container gray-bg">
      <h1 className="admin-title">Carta de productos</h1>
      <div className="flex-add-container">
        <button onClick={() => toggleShow("product")}>Nuevo producto{" "}<FontAwesomeIcon icon={faPlus} /></button>
        <button onClick={() => toggleShow("category")}>Categorías{" "}<FontAwesomeIcon icon={faEdit}/></button>
        <button onClick={() => toggleShow("extra")}>Extras para burger{" "}<FontAwesomeIcon icon={faEdit} /></button>
      </div>
      {showElement === "category" && <CategoryTable />}
      {showElement === "product" && <AddProductForm />}
      {showElement === "extra" && <ExtraList />}
      <ListaProductos />
    </div>
  );
};

export default AdminStore;
