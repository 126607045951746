import React, { useState, useEffect } from "react";
import axios from "axios";
import MenuCategory from "./MenuCategory";
import "./Menu.css";
import { useShoppingContext } from "../../../context/ShoppingContext";
import Loading from "../../../components/Loading/Loading";
const Menu = () => {
  const {
    agregarAlCarrito,
    modificarCantidad,
    toggleCart,
  } = useShoppingContext();
  const [categoriasConProductos, setCategoriasConProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtroCategoria, setFiltroCategoria] = useState(null);
  const [nombreCategoriaFiltrada, setNombreCategoriaFiltrada] = useState("");
  const handleFiltrarCategoria = (categoria_id, nombre) => {
    setFiltroCategoria(categoria_id);
    setNombreCategoriaFiltrada(nombre);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/categorias`)
      .then((response) => {
        const categoriasData = response.data;
        if (Array.isArray(categoriasData)) {
          const categoriasActivas = categoriasData.filter(
            (categoria) => categoria.activo
          );
          setCategoriasConProductos(categoriasActivas);
          if (categoriasActivas.length === 1) {
            // Si solo hay una categoría activa, filtrar automáticamente por ella
            setFiltroCategoria(categoriasActivas[0].categoria_id);
            setNombreCategoriaFiltrada(categoriasActivas[0].nombre);
          }
          setLoading(false);
        } else {
          console.error("No hay categorías agregadas.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error al obtener categorías:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="store-container">
      {categoriasConProductos.length > 1 && (
        <div className="categoria-filtros">
          <button
            className={filtroCategoria === null ? "categoria-activa filtro-button" : "filtro-button"}
            onClick={() => {
              setFiltroCategoria(null);
              setNombreCategoriaFiltrada("");
            }}
          >
            Todos
          </button>
          {categoriasConProductos.map((categoria) => (
            <button
              key={categoria.categoria_id}
              className={
                filtroCategoria === categoria.categoria_id
                  ? "categoria-activa"
                  : "filtro-button"
              }
              onClick={() =>
                handleFiltrarCategoria(categoria.categoria_id, categoria.nombre)
              }
            >
              {categoria.nombre === "Hamburguesas" ? "Burgers" : categoria.nombre}
            </button>
          ))}
        </div>
      )}

      {loading ? (
        <p></p>
      ) : (
        <div className="menu-options">
          {filtroCategoria ? (
            <MenuCategoryWrapper
              agregarAlCarrito={agregarAlCarrito}
              modificarCantidad={modificarCantidad}
              toggleCart={toggleCart}
              filtroCategoria={filtroCategoria}
              nombreCategoriaFiltrada={nombreCategoriaFiltrada}
            />
          ) : (
            categoriasConProductos.map((categoria) => (
              <MenuCategoryWrapper
                key={categoria.categoria_id}
                agregarAlCarrito={agregarAlCarrito}
                modificarCantidad={modificarCantidad}
                toggleCart={toggleCart}
                filtroCategoria={categoria.categoria_id}
                nombreCategoriaFiltrada={categoria.nombre}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

const MenuCategoryWrapper = ({
  agregarAlCarrito,
  modificarCantidad,
  toggleCart,
  filtroCategoria,
  nombreCategoriaFiltrada,
}) => {
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let params = { activo: true };
    if (filtroCategoria) {
      params.categoria_id = filtroCategoria;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: params,
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
          setLoading(false);
        } else {
          console.error("No hay productos agregados para esta categoría.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(
          `Error al obtener productos para la categoría ${nombreCategoriaFiltrada}:`,
          error
        );
        setLoading(false);
      });
  }, [filtroCategoria]);

  if (loading) {
    return <Loading text="Cargando productos"/>;
  }

  return (
    <MenuCategory
      category={nombreCategoriaFiltrada}
      category_id={filtroCategoria}
      menuItems={productos}
      agregarAlCarrito={agregarAlCarrito}
      modificarCantidad={modificarCantidad}
      toggleCart={toggleCart}
    />
  );
};

export default Menu;
