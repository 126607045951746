import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { faHeadset, faStar, faUsers } from "@fortawesome/free-solid-svg-icons";
import "./UserMenu.css";
import SignUpForm from "../../User/SignUpForm";
import SignInForm from "../../User/SignInForm";
import { useAuth } from "../../../context/AuthContext";
import { useShoppingContext } from "../../../context/ShoppingContext";

const UserMenu = () => {
  const location = useLocation();
  const { authenticatedUser, handleLogout } = useAuth();
  const {
    vaciarCarrito,
    setSelectedItems,
    selectedItemsOriginales,
    toggleUserMenu,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
    cartOpen,
    toggleCart,
    toggleRecomendados,
    toggleMostros,
  } = useShoppingContext();


  return (
    <>
      {authenticatedUser ? (
        <div className="user-menu">
          <ul className="user-menu-flex">
            {authenticatedUser && (
              <div className="info-container">
                <div className="userPoints-container"
                  onClick={toggleMostros}>
                  <div className="circle-container-star">
                    <FontAwesomeIcon className="star-icon" icon={faStar} />
                  </div>
                  <span className="userPoints">
                    {authenticatedUser.points} puntos Mostros
                  </span>
                </div>
                <div
                  className="userPoints-container"
                  onClick={toggleRecomendados}
                >
                  <div className="circle-container-star">
                    <FontAwesomeIcon className="star-icon" icon={faUsers} />
                  </div>
                  <span className="userPoints">
                    {authenticatedUser.recomendados}{" "}
                    {authenticatedUser.recomendados === 1
                      ? "recomendado"
                      : "recomendados"}
                  </span>
                </div>
              </div>
            )}
            <li className="datos-personales">
              <div className="user-data">
                <p>
                  {authenticatedUser.nombre} {authenticatedUser.apellido}
                </p>
                <p>{authenticatedUser.telefono}</p>
                <p>{authenticatedUser.email}</p>
              </div>
            </li>

            <li className="flex-space-around pd-20 pd-bt-0">
              <Link to="/cuenta/datos-personales">
                <button className="logout-btn" onClick={toggleUserMenu}>
                  editar datos
                </button>
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  toggleUserMenu();
                  vaciarCarrito();
                  setSelectedItems([...selectedItemsOriginales]);
                }}
                className="logout-btn"
              >
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
      ) : (
        <>
          {location.pathname !== "/login" &&
            location.pathname !== "/registrarse" && (
              <div className="user-menu">
                <div className="flex-space-around pd-20">
                  <button
                    className={`logout-btn`}
                    onClick={() => {
                      if (cartOpen) {
                        toggleCart();
                      }

                      if (showLoginForm) {
                        setShowLoginForm(false);
                      }

                      if (!showSignUpForm) {
                        setShowSignUpForm(true);
                      } else {
                        setShowSignUpForm(false);
                      }
                    }}
                  >
                    Registrarse
                  </button>
                  <button
                    className={`logout-btn`}
                    to="/login"
                    onClick={() => {
                      if (cartOpen) {
                        toggleCart();
                      }

                      if (!showLoginForm) {
                        setShowLoginForm(true);
                      } else {
                        setShowLoginForm(false);
                      }

                      if (showSignUpForm) {
                        setShowSignUpForm(false);
                      }
                    }}
                  >
                    Iniciar Sesión
                  </button>
                </div>
              </div>
            )}
        </>
      )}

     
    </>
  );
};

export default UserMenu;
