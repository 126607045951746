import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header/Header";
import NextButton from "./components/NextButton/NextButton"
import Footer from "./components/Footer/Footer"
import Menu from "./pages/Home/Menu/Menu";
import RegistroPedidos from "./pages/Admin/Pedidos/RegistroPedidos";
import SignUpForm from "./components/User/SignUpForm";
import SignInForm from "./components/User/SignInForm";
import Productos from "./pages/Admin/Productos/Productos";
import Usuarios from "./pages/Admin/Users/Usuarios";
import MisPedidos from "./pages/Pedidos/MisPedidos";
import CheckoutContact from "./pages/Checkout/CheckoutContact";
import CheckoutShipping from "./pages/Checkout/CheckoutShipping";
import CheckoutPayment from "./pages/Checkout/CheckoutPayment";
import PedidoConfirmado from "./pages/Pedidos/PedidoConfirmado";
import CodeManagement from "./pages/Admin/Descuentos/CodeManagement";
import {
  AuthProvider,
  useIsUserAdmin,
  useIsUserDev,
  useIsUserWorker,
} from "./context/AuthContext";
import { ShoppingProvider } from "./context/ShoppingContext";
import { useShoppingContext } from "./context/ShoppingContext";
import UsuariosRecomendados from "./components/Header/UserMenu/UsuariosRecomendados/UsuariosRecomendados";
import Clasificacion from "./components/Header/UserMenu/Clasificacion/Clasificacion";
import Panel from "./pages/Admin/Panel/Panel";

import EditarDatosPersonales from "./components/Header/Cuenta/EditarDatosPersonales";

import "./styles.css";
import LibroDeReclamaciones from "./components/LibroDeReclamaciones/LibroDeReclamaciones";

function App() {
  const isUserAdmin = useIsUserAdmin();
  const isUserWorker = useIsUserWorker();
  const isUserDev = useIsUserDev();
  const location = useLocation();

  const {
    authenticatedUser,
    isSidebarOpen,
    showLoginForm,
    showSignUpForm,
    showRecomendados,
    showMostros,
    fetchEstados,
  } = useShoppingContext();

  useEffect(() => {
    fetchEstados();
  }, []);

  // Comprueba si la ubicación actual coincide con "/login" o "/registrarse"
  const shouldShowNextButton = ![
    "/login",
    "/registrarse",
    "/registro-de-pedidos",
    "/administrar-productos",
    "/administrar-usuarios",
    "/checkout/cart",
    "/checkout/payment",
    "/cuenta/datos-personales",
    "/panel",
    "/mis-pedidos",
    "/libro-de-reclamaciones",
    "/pedido-confirmado/:pedidoId/:trackId", // Nueva ruta excluida
  ].includes(location.pathname);

    // Comprueba si la ubicación actual coincide con "/login" o "/registrarse"
    const shouldShowFooter = ![
      "/login",
      "/registrarse",
      "/registro-de-pedidos",
      "/administrar-productos",
      "/administrar-usuarios",
      "/checkout/cart",
      "/checkout/payment",
      "/cuenta/datos-personales",
      "/panel",
      "/mis-pedidos",
      "/pedido-confirmado/:pedidoId/:trackId", // Nueva ruta excluida
    ].includes(location.pathname);

  return (
    <div className={isSidebarOpen ? "app overflow-hidden" : "app"}>
      <Header />
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/mis-pedidos" element={<MisPedidos />} />

        <Route path="/registrarse" element={<SignUpForm />} />
        <Route path="/login" element={<SignInForm />} />

        {isUserDev || isUserAdmin ? (
          <>
            <Route path="/panel" element={<Panel />} />
            <Route path="/administrar-productos" element={<Productos />} />
            <Route path="/administrar-usuarios" element={<Usuarios />} />
            <Route path="/registro-de-pedidos" element={<RegistroPedidos />} />
            <Route path="/codigos-descuento" element={<CodeManagement />} />
          </>
        ) : (
          <Route path="/" element={<Menu />} />
        )}

        {isUserWorker && (
          <>
            <Route path="/panel" element={<Panel />} />
            <Route path="/administrar-productos" element={<Productos />} />
            <Route path="/registro-de-pedidos" element={<RegistroPedidos />} />
          </>
        )}

        <Route path="/menu" element={<Menu />} />

        {/* Example of other possible routes */}
        <Route path="/checkout" element={<CheckoutContact />} />
        <Route path="/checkout/shipping" element={<CheckoutShipping />} />
        <Route path="/checkout/payment" element={<CheckoutPayment />} />

        <Route
          path="/pedido-confirmado/:pedidoId/:trackId"
          element={<PedidoConfirmado />}
        />
        <Route
          path="cuenta/datos-personales"
          element={<EditarDatosPersonales />}
        />
        {/*<Route path="/stripe" element={<StripeContainer />} />
          <Route path="/editAdmin" element={<EditAdmin />} /> */}

          
        <Route path="/libro-de-reclamaciones" element={<LibroDeReclamaciones />} />

      </Routes>
      {shouldShowNextButton && <NextButton />}
      {shouldShowFooter && <Footer />}
      {!authenticatedUser && showLoginForm && <SignInForm />}
      {!authenticatedUser && showSignUpForm && <SignUpForm />}
      {showRecomendados && <UsuariosRecomendados />}
      {showMostros && <Clasificacion />}
    </div>
  );
}

function MainApp() {
  return (
    <Router>
      <ShoppingProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ShoppingProvider>
    </Router>
  );
}

export default MainApp;
