import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./WhatsappButton.css";

const WhatsAppButton = () => {
  // Número de WhatsApp y mensaje
  const phoneNumber = "+51907386999";
  const message = "¡Hola Mostro's!";

  // Crear la URL de WhatsApp
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

  return (
    <a href={whatsappUrl} id="wa" className="social" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon id="wa-icon" className="social-icon" icon={faWhatsapp} /> {/* Agregar el ícono de WhatsApp */}
    </a>
  );
};

export default WhatsAppButton;
