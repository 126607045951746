import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./Checkout.css";
import Details from "../../components/Details/Details";
import { useLocation } from "react-router-dom";
import { useShoppingContext } from "../../context/ShoppingContext";
import { useAuth } from "../../context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import ShippingInfo from "../../components/ShippingInfo/ShippingInfo";
import Loading from "../../components/Loading/Loading";

const CheckoutPayment = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { shippingInfo } = location.state || {};
  const {
    calcularTotal,
    carrito,
    vaciarCarrito,
    setSelectedItems,
    selectedItemsOriginales,
    toggleCart,
    costoEnvio,
    setCostoEnvio,
    precioFinalConDescuento,
    descuento,
    codigoDescuento,
    setDescuento,
    setCodigoDescuento,
    setPrecioFinalConDescuento,
    setMontoTotal,
    cartItemCount,
    metodoPago,
    setMetodoPago,
    subtotal,
    setCardNumber,
    cardName,
    setCardName,
    cardExpiration,
    setCardExpiration,
    cardCvv,
    setCardCvv,
    setDni,
    setRazonSocial,
    setRuc,
    setComprobante,
    razonSocial,
    dni,
    ruc,
    comprobante,
    instrucciones,
    setInstrucciones,
    setOpen,
    estados,
    fetchEstados,
    extras,
  } = useShoppingContext();
  const montoTotal = calcularTotal(carrito);
  const { authenticatedUser } = useAuth();
  const [trackId, setTrackId] = useState(null);
  const soundRef = useRef(null);
  const [cargando, setCargando] = useState(false);
  const openEstado = estados.find((estado) => estado.nombre === "Abierta");
  const { calcularEnvio } = useShoppingContext();

  const { distrito } = shippingInfo || {};

  useEffect(() => {
    if (distrito) {
      const nuevoCostoEnvio = calcularEnvio(distrito);
      setCostoEnvio(nuevoCostoEnvio);
    }
  }, [distrito]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchEstados();
    }, 3000); // Fetch estados data every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlTrackId = searchParams.get("trackId");
    if (urlTrackId) {
      setTrackId(urlTrackId);
    } else {
      setTrackId(uuidv4());
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCargando(true);
    try {
      const user_id = authenticatedUser ? authenticatedUser.id : "0";

      const pedidoTrackId = trackId;

      console.log("Enviando solicitud para crear pedido...");
      const pedidoResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/crear-pedido`,
        {
          user_id,
          subtotal: subtotal,
          monto_total: precioFinalConDescuento,
          direccion: shippingInfo.direccion
            ? `${shippingInfo.direccion}, ${shippingInfo.distrito}`
            : `${shippingInfo.distrito}`,
          distrito: `${shippingInfo.distrito}`,
          costo_envio: costoEnvio.toFixed(2),
          total: subtotal + costoEnvio,
          descuento: descuento,
          codigo: codigoDescuento,
          nombre: `${shippingInfo.nombre} ${shippingInfo.apellidos}`,
          telefono: `${shippingInfo.telefono}`,
          email: `${shippingInfo.email}`,
          metodo_pago: metodoPago,
          razon_social: razonSocial,
          dni: dni,
          ruc: ruc,
          comprobante: comprobante,
          instrucciones: instrucciones,
          estado_pedido: "Activo",
          track_id: pedidoTrackId,
        }
      );

      const nuevoPedidoId = pedidoResponse.data.pedido.id;

      for (const [index, producto] of carrito.entries()) {
        console.log(
          `Enviando solicitud para crear detalle de pedido (${index + 1}/${
            carrito.length
          })...`
        );

        const detallePedidoResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/detalles-de-pedido`,
          {
            user_id,
            item_id: index + 1,
            pedido_id: nuevoPedidoId,
            producto_id: producto.producto_id,
            cantidad: producto.cantidad,
            precio_unitario: producto.precio,
          }
        );

        // Verificar si producto.extras es un array antes de iterar sobre él
        if (Array.isArray(producto.extras)) {
          for (const extra of producto.extras) {
            console.log(
              `Enviando solicitud para crear detalle de extra (${extra.id}) del producto (${producto.producto_id})...`
            );
            const detalleExtrasResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/extras/detalles-de-extra`,
              {
                item_id: index + 1,
                pedido_id: nuevoPedidoId,
                producto_id: producto.producto_id,
                extra_id: extra.id,
                extra: extra.nombre || "Nombre del extra no disponible",
                cantidad: extra.cantidad || 1,
                precio_unitario: extra.precio || 0,
                agotado: extra.agotado || false,
              }
            );
            console.log(
              `Respuesta del servidor al crear detalle de extra (${extra.id}) del producto (${producto.producto_id}):`,
              detalleExtrasResponse
            );
          }
        }
      }

      console.log("Respuesta del servidor al crear pedido:", pedidoResponse);
      if (pedidoResponse.status === 201) {
        navigate(`/pedido-confirmado/${nuevoPedidoId}/${pedidoTrackId}`);
      }
      // Limpia el estado y la información de descuento
      vaciarCarrito();
      setDescuento(0);
      setCodigoDescuento("");
      // Restablece el estado en el contexto de compras
      setMontoTotal(0);
      setCostoEnvio(0);
      setPrecioFinalConDescuento(0);
      setMetodoPago("");
      setCardExpiration("");
      setCardCvv("");
      setCardName("");
      setCardNumber("");
      setComprobante("");
      setRuc("");
      setDni("");
      setRazonSocial("");
      setInstrucciones("");
      setSelectedItems([]);
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      setErrorMessage(
        "Hubo un error al procesar el pago. Por favor, inténtalo de nuevo."
      );
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlTrackId = searchParams.get("trackId");
    if (urlTrackId) {
      setTrackId(urlTrackId); // Utilizar el trackId de la URL si está presente
    } else {
      setTrackId(uuidv4()); // Generar un nuevo trackId si no está presente en la URL
    }
  }, [location.search]);

  const contentBoxStyle = {
    width: location.pathname === "/checkout/payment" ? "100%" : "80%",
    margin: "20px auto",
  };

  const reproducirSonido = () => {
    if (soundRef.current) {
      soundRef.current.play();
    }
  };

  return (
    <div className="confirmar-pedido">
      <div className="details-container">
        <Details
          carrito={carrito}
          costoEnvio={costoEnvio}
          distrito={distrito}
          precioFinalConDescuento={precioFinalConDescuento}
        />
      </div>

      <form
        className="payment-box "
        onSubmit={handleSubmit}
      >
          <div className="shipping-details">
            {shippingInfo ? (
              <ShippingInfo></ShippingInfo>
            ) : (
              <>
                <p>Los datos de envío no están disponibles.</p>
                <Link to="/menu">Seguir comprando</Link>
              </>
            )}
          </div>

        <div className="bottom">
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          {openEstado?.activo === true ? (
            <div className="fixed-checkout-options">
              <div className="checkout-options">
                <Link to="/checkout" className="checkout-option">
                  Editar Datos
                </Link>
                <div className="checkout-option" onClick={() => toggleCart()}>
                  Editar Pedido
                </div>
              </div>
              {carrito.length > 0 && (
                <button
                  type="submit"
                  className={
                    comprobante && metodoPago
                      ? "carrito-button carrito-button-pedido"
                      : "carrito-button-pedido disabled"
                  }
                  disabled={comprobante && metodoPago ? false : true}
                >
                  <p
                    className={
                      comprobante && metodoPago
                        ? "item-count "
                        : "item-count disabled bg-g"
                    }
                  >
                    {cartItemCount}
                  </p>
                  <p>FINALIZAR COMPRA</p>
                  <p>S/. {precioFinalConDescuento.toFixed(2)}</p>
                </button>
              )}
            </div>
          ) : (
            <div className="tienda-cerrada">
              <h2>
                Lo sentimos, la tienda está cerrada. Regresa de 11 a.m. a 11
                p.m.
              </h2>
            </div>
          )}
        </div>
      </form>
      {cargando && <Loading text="Creando Pedido" />}
    </div>
  );
};

export default CheckoutPayment;
