import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhatsAppButton from "../WhatsappButton/WhatsappButton";
import { faAddressBook, faBook } from "@fortawesome/free-solid-svg-icons";
import "./Reclamaciones.css";

const LibroDeReclamaciones = () => {
  const [registro, setRegistro] = useState({
    fecha: "",
    comercio: {
      razonSocial: "JPLESB EIRL",
      ruc: "20610285083",
      direccion:
        "Avenida Javier Prado Este N° 4200 (Int CP 4 - 9 - Surco), Santiago de Surco 15023, Provincia de Lima, Perú",
    },
    nombreCompleto: "",
    domicilio: "",
    documentoIdentidad: "",
    tipoDocumento: "",
    numeroDocumento: "",
    email: "",
    telefono: "",
    esMenorEdad: false,

    montoReclamado: "",
    descripcion: "",
    fechaPedido: "",
    tipoReclamo: "",
    detallesReclamo: "",
    detallesPedido: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRegistro((prevRegistro) => ({
      ...prevRegistro,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes enviar los datos del formulario a donde los necesites, por ejemplo, a través de una solicitud HTTP
    console.log(registro);
    // Luego puedes limpiar el formulario o mostrar un mensaje de confirmación
    // setRegistro({...}); // Limpiar formulario
  };

  return (
    <div className="content-container">
      <form className="reclamaciones-form" onSubmit={handleSubmit}>
        <div className="libro-title-container">
          <h4>Libro de Reclamaciones</h4>
        </div>

        <h4>HOJA DE RECLAMO</h4>
        <p>
          Conforme a lo establecido en el código de Protección y Defensa del
          Consumidor, este establecimiento cuenta con un Libro de Reclamaciones
          a tu disposición, donde puedes registrar cualquier queja o reclamo
          relacionado con un pedido o transacción que hayas realizado.
        </p>

        <div className="lr-group local">
          <div className="form-group">
            <b className="form-group-label">
              Fecha de registro: {new Date().toLocaleDateString()}
            </b>
            <b className="form-group-label">
              Razón Social: {registro.comercio.razonSocial}
            </b>
            <b className="form-group-label">RUC {registro.comercio.ruc}</b>
            <b className="form-group-label">
              Dirección: {registro.comercio.direccion}
            </b>
          </div>
        </div>
        <div className="lr-group">
          <h4>1. Identificador del Consumidor Reclamante</h4>
          <div className="form-group">
            <label className="form-group-label">Nombre Completo</label>
            <input
              type="text"
              name="nombreCompleto"
              value={registro.nombreCompleto}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label">Domicilio</label>
            <input
              type="text"
              name="domicilio"
              value={registro.domicilio}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label">Documento de Identidad</label>
            <select
              name="tipoDocumento"
              value={registro.tipoDocumento}
              onChange={handleChange}
            >
              <option value="">Selecciona un documento</option>
              <option value="DNI">DNI</option>
              <option value="PASAPORTE">Pasaporte</option>
            </select>
          </div>
          <div className="form-group">
            <label className="form-group-label">Número de documento</label>
            <input
              type="text"
              name="numeroDocumento"
              value={registro.numeroDocumento}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label">Email</label>
            <input
              type="email"
              name="email"
              value={registro.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="form-group-label">Teléfono Fijo / Celular</label>
            <input
              type="text"
              name="telefono"
              value={registro.telefono}
              onChange={handleChange}
            />
          </div>
          <div className="menoredad">
            <h2>¿Eres menor de edad?</h2>
            <div className="si-no">
              <label>
                SI{" "}
                <input
                  type="radio"
                  name="esMenorEdad"
                  value="sí"
                  checked={registro.esMenorEdad === "sí"}
                  onChange={handleChange}
                />
              </label>
              <label>
                NO{" "}
                <input
                  type="radio"
                  name="esMenorEdad"
                  value="no"
                  checked={registro.esMenorEdad === "no"}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="lr-group">
          <h4>2. Identificación del bien contratado</h4>
          <div className="reclamo-queja">
            <label>
              Producto:{" "}
              <input
                type="radio"
                name="tipoReclamo"
                value="Producto"
                checked={registro.tipoReclamo === "Producto"}
                onChange={handleChange}
              />
            </label>
            <label>
              Servicio:{" "}
              <input
                type="radio"
                name="tipoReclamo"
                value="Servicio"
                checked={registro.tipoReclamo === "Servicio"}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group">
            <label className="form-group-label">Monto Reclamado</label>
            <input
              type="text"
              name="montoReclamado"
              value={registro.montoReclamado}
              onChange={handleChange}
            />
          </div>


          <div className="form-group">
            <label className="form-group-label">Descripción</label>
            <textarea
              name="detallesPedido"
              value={registro.detallesPedido}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        <div className="lr-group">
          <h4>3. Detalle de Reclamación y Pedido del Consumidor</h4>
          <div className="reclamo-queja">
            <label>
              Reclamo:{" "}
              <input
                type="radio"
                name="tipoReclamo"
                value="Reclamo"
                checked={registro.tipoReclamo === "Reclamo"}
                onChange={handleChange}
              />
            </label>
            <label>
              Queja:{" "}
              <input
                type="radio"
                name="tipoReclamo"
                value="Queja"
                checked={registro.tipoReclamo === "Queja"}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-group">
            <label className="form-group-label">
              Detalles del reclamo o la queja
            </label>
            <textarea
              name="detallesReclamo"
              value={registro.detallesReclamo}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label className="form-group-label">Información del Pedido</label>
            <textarea
              name="detallesPedido"
              value={registro.detallesPedido}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <button className="enviar-queja" type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default LibroDeReclamaciones;
