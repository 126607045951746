import React, { useState } from "react";
import "./PaymentMethods.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCreditCard,
  faCreditCardAlt,
  faEye,
  faEyeSlash,
  faHashtag,
  faMoneyBill,
  faMoneyBill1,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useShoppingContext } from "../../context/ShoppingContext";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";

const PaymentMethods = ({ title }) => {
  const {
    metodoPago,
    cardNumber,
    setCardNumber,
    cardName,
    setCardName,
    cardExpiration,
    setCardExpiration,
    cardCvv,
    setCardCvv,
    setMetodoPago,
    comprobante,
    setComprobante,
    setDni,
    setRazonSocial,
    setRuc,
    dni,
    razonSocial,
    ruc,
  } = useShoppingContext();

  const [showCvv, setShowCvv] = useState(false);
  const [iconsBlink, setIconsBlink] = useState(false);
  const [highlighted, setHighlighted] = useState(false); // Estado para controlar si se resalta el contenedor

  const toggleCvvVisibility = () => {
    setShowCvv(!showCvv);
  };

  const handleMetodoPago = (metodo) => {
    setMetodoPago(metodo);
    console.log(metodo);
  };

  const handleComprobante = (comprobante) => {
    setComprobante(comprobante);
    if (dni) {
      setDni("");
    }
    if (razonSocial) {
      setRazonSocial("");
    }
    if (ruc) {
      setRuc("");
    }
    console.log(comprobante);
  };

  const toggleHighlight = () => {
    if (metodoPago) {
      setHighlighted(false);
    } else setHighlighted(true); // Cambiar el estado de resaltado al hacer clic en el contenedor
  };

  // Función para formatear la fecha de caducidad
  const formatExpirationDate = (input) => {
    // Eliminar caracteres que no sean números
    const cleanedInput = input.replace(/[^\d]/g, "");
    // Separar el input en mes y año
    let formattedInput = cleanedInput.slice(0, 2);
    if (cleanedInput.length > 2) {
      formattedInput += "/" + cleanedInput.slice(2, 4);
    }
    return formattedInput;
  };

  // Manejar cambios en la fecha de caducidad
  const handleChangeExpirationDate = (e) => {
    const input = e.target.value;
    const formattedInput = formatExpirationDate(input);
    setCardExpiration(formattedInput);
  };

  const formatCardNumber = (input) => {
    // Eliminar cualquier espacio en blanco en el número de tarjeta
    const cardNumberWithoutSpaces = input.replace(/\s/g, "");
    // Aplicar el formato de espacio cada cuatro dígitos
    const formattedCardNumber = cardNumberWithoutSpaces.replace(
      /(\d{4})/g,
      "$1 "
    );
    // Limitar la longitud máxima a 19 caracteres (16 dígitos + 3 espacios)
    return formattedCardNumber.trim().slice(0, 19);
  };

  const handleChangeCardNumber = (e) => {
    const input = e.target.value;
    const formattedInput = formatCardNumber(input);
    setCardNumber(formattedInput);
  };

  return (
    <div className={`flex-order-2 `}>
      <div className="method-pay">
        <h3 className="title-pay">{title}</h3>
        {title === "Método de pago" ? (
          <div className="container-payment">
            <div className="methods">
              <div
                className={
                  metodoPago === "Tarjeta online"
                    ? "pay-online method-groups "
                    : "pay-online method-groups"
                }
              >
                <div className="flex-space-between">
                  <p className={!metodoPago || metodoPago ==="Yape" ? "color-black" : "method-title" } onClick={toggleHighlight}>
                    {metodoPago === "Yape"
                      ? "Pago con Yape"
                      : metodoPago === "Tarjeta online"
                      ? "Pago con Tarjeta"
                      : "Selecciona un método"}
                  </p>
                  <div className="icon-group">
                    <FontAwesomeIcon
                      className={
                        metodoPago === "Tarjeta online"
                          ? `mg-r-3 card bg-r-icon ${
                              highlighted ? "highlighted" : ""
                            }`
                          : `mg-r-3 card ${highlighted ? "highlighted" : ""}`
                      }
                      icon={faCreditCard}
                      onClick={() => {
                        if (highlighted) {
                          setHighlighted(!highlighted);
                        }
                        handleMetodoPago("Tarjeta online");
                      }}
                    />
                    <img
                      className={
                        metodoPago === "Yape"
                          ? `mg-r-3  bg-r-icon ${
                              highlighted ? "highlighted" : ""
                            }`
                          : `mg-r-3 ${highlighted ? "highlighted" : ""}`
                      }
                      width={40}
                      src="/images/logos/yape.png"
                      onClick={() => {
                        if (highlighted) {
                          setHighlighted(!highlighted);
                        }
                        handleMetodoPago("Yape");
                      }}
                    />
                  </div>
                </div>
                {metodoPago === "Tarjeta online" && (
                  <div className="card-method-container">
                    <div className="card-details">
                      <div className="card-name">
                        <label
                          className="card-number-icon"
                          htmlFor="cardNumber"
                        >
                          <FontAwesomeIcon
                            className="card-icon"
                            icon={faUser}
                          />
                        </label>
                        <input
                          type="text"
                          className="card-input card-name-input"
                          placeholder="Nombre del titular"
                          value={cardName}
                          onChange={(e) => setCardName(e.target.value)}
                        />
                      </div>
                      <div className="card-number">
                        <label
                          className="card-number-icon"
                          htmlFor="cardNumber"
                        >
                          <FontAwesomeIcon
                            className="card-icon"
                            icon={faCreditCard}
                          />
                        </label>
                        <input
                          type="text"
                          id="cardNumber"
                          className="card-input card-number-input"
                          placeholder="Número de tarjeta"
                          value={cardNumber}
                          onChange={handleChangeCardNumber}
                        />
                      </div>
                      <div className="flex-card-data">
                        <div className="flex-card-data-l">
                          <label
                            className="card-number-icon"
                            htmlFor="cardNumber"
                          >
                            <FontAwesomeIcon
                              className="card-icon"
                              icon={faCalendar}
                            />
                          </label>
                          <input
                            type="text"
                            className="card-input card-expiration-input"
                            placeholder="MM/AA"
                            value={cardExpiration}
                            onChange={handleChangeExpirationDate}
                          />
                        </div>

                        <div className="flex-card-data-r">
                          <label
                            className="card-number-icon"
                            htmlFor="cardNumber"
                          >
                            <FontAwesomeIcon
                              className="card-icon"
                              icon={faHashtag}
                            />
                          </label>
                          <input
                            type={showCvv ? "text" : "password"}
                            className="card-input card-cvv-input"
                            placeholder="CVV"
                            maxLength={4}
                            value={cardCvv}
                            onChange={(e) => setCardCvv(e.target.value)}
                          />
                          <FontAwesomeIcon
                            icon={!showCvv ? faEyeSlash : faEye}
                            className="cvv-eye"
                            onClick={toggleCvvVisibility}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {metodoPago === "Yape" && (
                <h3 className="yape-text">
                  * Al finalizar la compra serás redirigido a la opción de pago
                  con Yape *
                </h3>
              )}
            </div>
          </div>
        ) : (
          <div className="container-payment">
            <div className="methods">
              <div className="comprobante">
                <label className="mg-r-3">
                  <input
                    type="radio"
                    name="comprobante"
                    value="Boleta"
                    onChange={() => {
                      handleComprobante("Boleta");
                    }}
                  />
                  Boleta
                </label>
                <label className="mg-r-3">
                  <input
                    type="radio"
                    name="comprobante"
                    value="Factura"
                    onChange={() => {
                      handleComprobante("Factura");
                    }}
                  />
                  Factura
                </label>
              </div>
              {title === "Tipo de comprobante" && comprobante === "Boleta" && (
                <div className="card-method-container">
                  <div className="card-details">
                    <div className="card-name">
                      <input
                        type="text"
                        className="card-input card-name-input"
                        placeholder="DNI (Opcional)"
                        maxLength={8}
                        value={dni}
                        onChange={(e) => setDni(e.target.value)}
                      />

                      <p>
                        *Ingresa tu DNI si deseas recibir una boleta electrónica
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {title === "Tipo de comprobante" && comprobante === "Factura" && (
                <div className="card-method-container">
                  <div className="card-details">
                    <div className="card-name">
                      <input
                        type="text"
                        className="card-input card-name-input"
                        placeholder="Razón Social"
                        value={razonSocial}
                        onChange={(e) => setRazonSocial(e.target.value)}
                        required
                      />
                    </div>
                    <div className="card-name">
                      <input
                        type="text"
                        className="card-input card-name-input"
                        placeholder="RUC"
                        maxLength={11}
                        value={ruc}
                        onChange={(e) => setRuc(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethods;
