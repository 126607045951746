import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Details from "../../components/Details/Details";
import "../../styles.css";
import "./Checkout.css";
import { useAuth } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useShoppingContext } from "../../context/ShoppingContext";

const CheckoutContact = () => {
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [costoEnvio, setCostoEnvio] = useState("");
  const [interior, setInterior] = useState("");
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);

  const { authenticatedUser } = useAuth();

  const {
    detailsVisible,
    setDetailsVisible,
    isRotated,
    setRotated,
    showLoginSection,
    setShowLoginSection,
    toggleCart,
    cartOpen,
    showLoginForm,
    showSignUpForm,
    setShowLoginForm,
    setShowSignUpForm,
    distrito,
    setDistrito,
    calcularEnvio,
  } = useShoppingContext();

  useEffect(() => {
    if (authenticatedUser) {
      setNombre(authenticatedUser.nombre || "");
      setApellidos(authenticatedUser.apellido || "");
      setEmail(authenticatedUser.email || "");
      setTelefono(authenticatedUser.telefono || "");
      setDireccion(authenticatedUser.direccion || "");
      setDistrito(authenticatedUser.distrito || "");
      setCostoEnvio(calcularEnvio(authenticatedUser.distrito) || 0);
    } else {
      setNombre("");
      setApellidos("");
      setEmail("");
      setTelefono("");
      setDireccion("");
      setDistrito("");
      setCostoEnvio(0);
    }

    // Recuperar los datos de envío del localStorage al cargar el componente
    const savedShippingInfo = localStorage.getItem("shippingInfo");
    if (savedShippingInfo) {
      const parsedShippingInfo = JSON.parse(savedShippingInfo);
      setEmail(parsedShippingInfo.email);
      setNombre(parsedShippingInfo.nombre);
      setApellidos(parsedShippingInfo.apellidos);
      setTelefono(parsedShippingInfo.telefono);
      setDireccion(parsedShippingInfo.direccion);
      setDistrito(parsedShippingInfo.distrito);
      setCostoEnvio(parsedShippingInfo.costoEnvio);
    }
  }, [authenticatedUser]);

  const handleInputChange = (e, setStateFunction) => {
    setStateFunction(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let shippingInfo;

    if (email && nombre && apellidos && telefono) {
      shippingInfo = {
        email,
        direccion,
        distrito,
        nombre,
        apellidos,
        telefono,
      };
    } else if (changeEmail && nombre && apellidos && telefono) {
      shippingInfo = {
        email: newEmail,
        direccion,
        distrito,
        nombre,
        apellidos,
        telefono,
      };
    }

    if (shippingInfo) {
      // Calcular el costo de envío
      const nuevoCostoEnvio = calcularEnvio(distrito);
      setCostoEnvio(nuevoCostoEnvio);

      shippingInfo = {
        ...shippingInfo,
        costoEnvio: nuevoCostoEnvio,
      };

      localStorage.setItem("shippingInfo", JSON.stringify(shippingInfo));
      navigate("/checkout/payment", { state: { shippingInfo } });
    } else {
      alert("Por favor, complete todos los campos.");
    }
  };

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
    setRotated(!isRotated);
  };

  const handleSkipLogin = () => {
    setShowLoginSection(false);
  };

  return (
    <div className="content-container">
      <div className="content-box">
        <h2 className="heading">INFORMACIÓN DE CONTACTO</h2>
        {/* Sección adicional para iniciar sesión si el usuario no está autenticado */}
        {showLoginSection && !authenticatedUser && (
          <div className="login-section">
            <span>
              ¿Ya tienes una cuenta?{" "}
              <a
                onClick={() => {
                  if (cartOpen) {
                    toggleCart();
                  }

                  if (!showLoginForm) {
                    setShowLoginForm(true);
                  } else {
                    setShowLoginForm(false);
                  }

                  if (showSignUpForm) {
                    setShowSignUpForm(false);
                  }
                }}
              >
                Inicia sesión
              </a>{" "}
              o{" "}
              <a
                onClick={() => {
                  if (cartOpen) {
                    toggleCart();
                  }

                  if (showLoginForm) {
                    setShowLoginForm(false);
                  }

                  if (!showSignUpForm) {
                    setShowSignUpForm(true);
                  } else {
                    setShowSignUpForm(false);
                  }
                }}
              >
                Regístrate
              </a>
              .
            </span>
            <span className="omitir" type="button" onClick={handleSkipLogin}>
              <FontAwesomeIcon icon={faTimes} /> Comprar sin iniciar sesión{" "}
            </span>
          </div>
        )}
        <form className="checkout-form-container" onSubmit={handleSubmit}>
          <div className="form">
            <div className="input-group">
              <div id="nombre-container" className="flex-form-input">
                <label htmlFor="nombre" className="label">
                  Nombre <span className="color-red-bold">*</span>
                </label>
                <input
                  type="text"
                  id="nombre"
                  value={nombre}
                  onChange={(e) => handleInputChange(e, setNombre)}
                  className="input"
                  required
                />
              </div>
            </div>
            <div className="input-group">
              <div id="apellidos-container" className="flex-form-input">
                <label
                  id="apellidos-label"
                  htmlFor="apellidos"
                  className="label"
                >
                  Apellido <span className="color-red-bold">*</span>
                </label>
                <input
                  type="text"
                  id="apellidos"
                  value={apellidos}
                  onChange={(e) => handleInputChange(e, setApellidos)}
                  className="input"
                  required
                />
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="email" className="label">
                Correo Electrónico <span className="color-red-bold">*</span>
              </label>
              {authenticatedUser && !changeEmail ? (
                <div className="change-email-section">
                  <span className="email-de-usuario">
                    {authenticatedUser.email}
                  </span>
                  <p className="cambiar" onClick={() => setChangeEmail(true)}>
                    Cambiar
                  </p>
                </div>
              ) : (
                <input
                  type="email"
                  id="email"
                  value={changeEmail ? newEmail : email}
                  onChange={(e) =>
                    changeEmail
                      ? setNewEmail(e.target.value)
                      : handleInputChange(e, setEmail)
                  }
                  className="input"
                  required
                  disabled={authenticatedUser && !changeEmail}
                />
              )}
            </div>
            <div className="input-group">
              <label htmlFor="telefono" className="label">
                Número de Teléfono <span className="color-red-bold">*</span>
              </label>
              <input
                type="tel"
                id="telefono"
                value={telefono}
                onChange={(e) => handleInputChange(e, setTelefono)}
                className="input"
                required
              />
            </div>
            <input
              type="submit"
              value="Siguiente"
              className="next-step-button"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutContact;
