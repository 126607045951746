import React, { useState, useEffect } from "react";
import { useShoppingContext } from "../../../context/ShoppingContext";
import axios from "axios";

const EditarExtra = () => {
  const {
    editarVisible,
    toggleEditar,
    editingExtra,
    setEditingExtra,
    cargarExtras,
  } = useShoppingContext();

  const [formData, setFormData] = useState({
    nombre: "",
    precio: "",
    activo: true,
    agotado: true,
  });

  useEffect(() => {
    if (editingExtra) {
      setFormData({
        nombre: editingExtra.nombre,
        precio: editingExtra.precio,
        activo: editingExtra.activo, // Asignar el valor inicial de activo
        agotado: editingExtra.agotado, // Asignar el valor inicial de agotado
      });
    }
  }, [editingExtra]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/extras/${editingExtra.id}`,
        formData
      );
      console.log("Extra actualizado:", response.data);
      setEditingExtra(null); // Reinicia el extra que se está editando
      cargarExtras(); // Recarga la lista de extras
      toggleEditar(); // Oculta el formulario de edición
    } catch (error) {
      console.error("Error al actualizar el extra:", error);
    }
  };

  const handleCancelar = () => {
    setEditingExtra(null); // Reinicia el extra que se está editando
    toggleEditar(); // Oculta el formulario de edición
  };

  return (
    <div className="extras-container">
      {editarVisible && (
        <div className="extras-content">
          <h2>Editar Extra</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Nombre:
                <input
                  required
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                />
              </label>
              <label>
                Precio:
                <input
                  required
                  type="number"
                  name="precio"
                  value={formData.precio}
                  onChange={handleChange}
                />
              </label>
              <label>
                Activo:
                <input
                  type="checkbox"
                  name="activo"
                  checked={formData.activo}
                  onChange={handleChange}
                />
              </label>
              <label>
                Agotado:
                <input
                  type="checkbox"
                  name="agotado"
                  checked={formData.agotado}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="form-actions">
              <button type="button" id="extra-cancel" onClick={handleCancelar}>
                Cancelar
              </button>
              <button type="submit">Actualizar Extra</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditarExtra;
