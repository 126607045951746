import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useShoppingContext } from "../../context/ShoppingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";
import bcrypt from "bcryptjs";

import "./form.css";

const SignInForm = () => {
  const { handleLogin, loginData, setLoginData, setSignin, signin } = useAuth();
  const { showLoginForm, showSignUpForm, setShowLoginForm, setShowSignUpForm } =
    useShoppingContext();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    obtenerUsuarios();
  }, []); // Obtener usuarios al cargar el componente

  const obtenerUsuarios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Resetear el mensaje de error
    setError("");

    console.log(loginData.email);

    // Verificar si el correo electrónico o el número de teléfono ya están registrados
    const user = users.find((user) => user.email === loginData.email);

    if (!loginData.email && !loginData.password) {
      setError("No has ingresado tus datos para iniciar sesión");
      return;
    }

    if (!loginData.email) {
      setError("Ingresa tu correo");
      return;
    }

    if (!user) {
      setError("email no registrado");
      return;
    }

    if (!loginData.password) {
      setError("Ingresa tu contraseña");
      return;
    }
    
    const isPasswordValid = await bcrypt.compare(loginData.password, user.password);

    if (!isPasswordValid) {
      setError("contraseña incorrecta");
      return;
    }

    setSignin(true);

    try {
      await handleLogin(loginData);
    } catch (error) {
      console.error("Error during login:", error);
    }
    if (showLoginForm) {
      setShowLoginForm(false);
    }
    if (showSignUpForm) {
      setShowSignUpForm(false);
    }
    console.log(error);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {signin ? (
        <Loading text={`Iniciando Sesión`}></Loading>
      ) : (
        <div className="flex-login-container">
          <div className="form-container">
            <div className="close-form-container">
              <FontAwesomeIcon
                icon={faTimes}
                className="form-close-button"
                onClick={() => {
                  setShowLoginForm(false);
                  setShowSignUpForm(false);
                }}
              />
            </div>

            <form onSubmit={handleSubmit} className="login-form">
              <Link
                to="/"
                onClick={() => {
                  if (showLoginForm) {
                    setShowLoginForm(false);
                  }
                }}
              >
                <img width="300px" src="/images/logos/icon.png" alt="Logo" />
              </Link>
              <h1 className="form-title">Iniciar sesión</h1>
              <ul>
                <div className="flex-column">
                  <li className="flex-input">
                    <label className="form-label">Dirección de email</label>
                    <input
                      type="email"
                      name="email"
                      value={loginData.email}
                      onChange={handleChange}
                      autoComplete="on"
                      className="form-input"
                    />
                  </li>
                  <li className="flex-input">
                    <label className="form-label">Contraseña</label>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={loginData.password}
                        onChange={handleChange}
                        autoComplete="off"
                        className="form-input"
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="password-icon"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                  </li>
                </div>
                {error && <div className="error-message">{error}</div>}{" "}
                {/* Mostrar el mensaje de error si existe */}
                <li>
                  <button type="submit" className="form-button">
                    Iniciar sesión
                  </button>
                </li>
                <li>ó</li>
                <li>
                  <p
                    className="pointer"
                    onClick={() => {
                      if (showLoginForm) {
                        setShowLoginForm(false);
                      }
                      if (!showSignUpForm) {
                        setShowSignUpForm(true);
                      }
                    }}
                  >
                    Registrarse
                  </p>
                </li>
              </ul>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SignInForm;
