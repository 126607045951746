import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useShoppingContext } from "../../context/ShoppingContext";
import axios from "axios";
import "./PedidoConfirmado.css";

const PedidoDetalle = () => {
  const {
    obtenerDetallesPedido,
    detallesPedido,
    obtenerProductos,
    productosOriginales,
  } = useShoppingContext();
  const [pedidoConfirmado, setPedidoConfirmado] = useState(null);
  const [loading, setLoading] = useState(true);
  const { pedidoId, trackId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pedidoResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`),
          obtenerDetallesPedido(pedidoId),
          obtenerProductos(),
        ]);

        setPedidoConfirmado(pedidoResponse.data);
      } catch (error) {
        console.error("Error al obtener los detalles del pedido:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pedidoId, obtenerDetallesPedido, obtenerProductos, trackId, navigate]);

  const renderPedidoStatus = () => {
    if (!loading) {
      const { estado_pedido } = pedidoConfirmado;

      switch (estado_pedido) {
        case "Activo":
          return (
            <div>
              <h2 className="pedido-titulo activo-text">Pedido Recibido</h2>
              <dd>
                Pedido creado el {formattedCreatedAt} a las{" "}
                {formattedCreatedTime}
              </dd>
            </div>
          );
        case "Confirmado":
          return (
            <div className="mg-b-10">
              <h4>{pedidoConfirmado?.nombre}</h4>
              {pedidoConfirmado.dni && <h4>DNI {pedidoConfirmado?.dni}</h4>}
              <h2 className="pedido-titulo en-camino-text">
                Tu pedido está siendo preparado
              </h2>
              <b style={{ textAlign: "center" }}>
                Pedido confirmado a las {formattedUpdatedAt}
              </b>
            </div>
          );

        case "En camino":
          return (
            <div className="mg-b-10">
              <h4>{pedidoConfirmado?.nombre}</h4>

              {pedidoConfirmado.dni && <h4>DNI {pedidoConfirmado?.dni}</h4>}
              <h2 className="pedido-titulo en-camino-text">
                {pedidoConfirmado.direccion !== "Recojo en tienda"
                  ? pedidoConfirmado.direccion
                  : "Recoge tu pedido en Calle Comandante Gustavo Jimenez 580, Magdalena"}
              </h2>
              <b style={{ textAlign: "center" }}>
                Pedido confirmado a las {formattedUpdatedAt}
              </b>
            </div>
          );

        case "Entregado":
          return (
            <div className="mg-b-10">
              <h2 className="pedido-titulo finalizado-text">
                ¡Tu pedido ha sido entregado con éxito!
              </h2>
              <b style={{ textAlign: "center" }}>
                Pedido entregado el {formattedCreatedAt} a las{" "}
                {formattedUpdatedAt}
              </b>
            </div>
          );
        default:
          return null;
      }
    }

    return null;
  };

  // Assuming detallesPedido is an object containing pedido details
  const formattedCreatedAt = pedidoConfirmado?.createdAt
    ? new Date(pedidoConfirmado.createdAt).toLocaleDateString("es-PE", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "-";
  const formattedCreatedTime = pedidoConfirmado?.createdAt
    ? new Date(pedidoConfirmado.createdAt).toLocaleTimeString("es-PE", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "-";

  const formattedUpdatedAt = pedidoConfirmado?.updatedAt
    ? new Date(pedidoConfirmado.updatedAt).toLocaleTimeString("es-PE", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "-";

  // Número de WhatsApp y mensaje
  const phoneNumber = "+51949833976";
  const message = `Habla Mostro! necesito ayuda con mi pedido ${pedidoConfirmado?.id}`;

  return (
    <div className="content-container">
      <div className="store-container">
        <div className="pedido-detalle-container">
        

            {renderPedidoStatus()}
      

          <div className="pedido-progress">
            <div
              className={`progress-circle${
                pedidoConfirmado?.estado_pedido === "Activo" ? " active" : ""
              }`}
            >
              <div className="circle"></div>
            </div>
            <div
              className={`progress-line${
                pedidoConfirmado?.estado_pedido === "Confirmado" ||
                pedidoConfirmado?.estado_pedido === "En camino"
                  ? " active"
                  : ""
              }`}
            ></div>
            <div
              className={`progress-circle${
                pedidoConfirmado?.estado_pedido === "Confirmado"
                  ? " active"
                  : ""
              }`}
            >
              <div className="circle"></div>
            </div>
            <div
              className={`progress-line${
                pedidoConfirmado?.estado_pedido === "En camino" ? " active" : ""
              }`}
            ></div>
            <div
              className={`progress-circle${
                pedidoConfirmado?.estado_pedido === "Entregado"
                  ? " active"
                  : ""
              }`}
            >
              <div className="circle"></div>
            </div>
          </div>
          <div className="flex-space-between step-texts">
            <div className="step-text">Recibido</div>
            <div className="step-text">En camino</div>
            <div className="step-text">Entregado</div>
          </div>

          <a
            href={`https://wa.me/${phoneNumber}/?text=${encodeURIComponent(
              message
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            ¿Necesitas ayuda? Escríbenos por WhatsApp
          </a>

          <div className="pedido-card-container">
            {Array.isArray(detallesPedido) && detallesPedido.length > 0 ? (
              detallesPedido.map((detalle, index) => {
                const productoDelDetalle = productosOriginales.find(
                  (producto) => producto.producto_id === detalle.producto_id
                );
                return (
                  <React.Fragment key={index}>
                    <article className="pedido-card">
                      <img
                        alt="imagen del producto"
                        src={productoDelDetalle?.img || "Cargando..."}
                        width={120}
                      />
                      <div>
                        <p>{productoDelDetalle?.nombre || "Cargando..."}</p>
                        <p>
                          <strong>Cantidad:</strong> {detalle.cantidad}
                        </p>
                        <p>
                          <strong>Precio:</strong> S/.{" "}
                          {detalle.precio_unitario.toFixed(2)}
                        </p>
                      </div>
                    </article>
                  </React.Fragment>
                );
              })
            ) : (
              <p>Creando pedido.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PedidoDetalle;
