import React, { useState, useEffect } from "react";
import axios from "axios";

import "./NextButton.css";
import { useAuth, useIsUserAdmin } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import Carrito from "../Carrito/Carrito";
import { useShoppingContext } from "../../context/ShoppingContext";
import SignUpForm from "../User/SignUpForm";
import SignInForm from "../User/SignInForm";
const NextButton = () => {
  const { authenticatedUser, handleLogout } = useAuth();
  const isUserAdmin = useIsUserAdmin();
  const location = useLocation();

  const shouldShowPedirAhora = !["/checkout", "/checkout/shipping", "/pedido-confirmado/:id/:trackid"].includes(
    location.pathname
  );


  const {
    carrito,
    vaciarCarrito,
    eliminarDelCarrito,
    modificarCantidad,
    selectedItems,
    setSelectedItems,
    handleItemSelectedChange,
    productos,
    setProductos,
    calcularTotal,
    calcularSubtotal,
    toggleCart,
    closeCart,
    cartOpen,
    showLoginForm,
    showSignUpForm,
    toggleSidebar,
    isSidebarOpen,
    cartItemCount,
    setCartItemCount,
  } = useShoppingContext();

  // Verificar si la ubicación actual es "/login" o "/registrarse"
  const isHomePage = location.pathname === "/";
  const isLoginPage = location.pathname === "/login";
  const isSignUpPage = location.pathname === "/registrarse";

  const [isScrolled, setIsScrolled] = useState(false);
  const [opcionesVisible, setOpcionesVisible] = useState(false);

  const toggleOpciones = () => {
    setOpcionesVisible(!opcionesVisible);
  };

  const hideOpciones = () => {
    setOpcionesVisible(false);
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop - 60, // Ajusta aquí el desplazamiento en píxeles
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      // Verificar si el usuario ha desplazado la página (top no es igual a 0)
      setIsScrolled(window.scrollY !== 0);
    };


    // Agregar un evento de desplazamiento para escuchar los cambios
    window.addEventListener("scroll", handleScroll);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: {
          activo: true,
        },
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
        } else {
          console.error("No hay productos agregados.");
        }
      })
      .catch((error) => {
        console.error("Error al obtener productos activos:", error);
      });
  }, [setProductos]);

  useEffect(() => {
    const totalItems = carrito.reduce(
      (total, item) => total + item.cantidad,
      0
    );
    setCartItemCount(totalItems);
  }, [carrito]);

  const [isTablet] = useState(window.innerWidth <= 768);

  return (
    <>
      {!(isLoginPage || isSignUpPage) && (
        <div className="nextBtn-container">
          <ul>
            

            {shouldShowPedirAhora && (
              <li>
                <button
                  onClick={() => {
                    toggleCart();
                    if (isSidebarOpen) {
                      toggleSidebar();
                    }
                  }}
                  className="siguiente"
                >
                  SIGUIENTE
                </button>
              </li>
            )}

            {/* <li className="payment_methods"> */}
              {/* <FontAwesomeIcon className="method" style={{ color: "rgb(51 162 76)" }} icon={faMoneyBillWave} />
              
              <FontAwesomeIcon className="method" style={{ color: "#fff" }} icon={faCcVisa} />
              <FontAwesomeIcon className="method" style={{ color: "#fff" }} icon={faCcMastercard} /> */}
              {/* <img src="/images/logos/yape.png" alt="Yape" width="22px"></img>
              <img src="/images/logos/plin.png" alt="Plin" width="22px"></img>
            </li> */}
          </ul>

          {cartOpen && (
            <Carrito
              carrito={carrito}
              eliminarDelCarrito={eliminarDelCarrito}
              modificarCantidad={modificarCantidad}
              cartOpen={cartOpen}
              toggleCart={toggleCart}
              menuItems={productos}
              setSelectedItems={setSelectedItems}
              handleItemSelectedChange={handleItemSelectedChange}
              calcularSubtotal={calcularSubtotal}
              calcularTotal={calcularTotal}
              vaciarCarrito={vaciarCarrito}
            />
          )}
          {cartOpen && <div className="overlay" onClick={closeCart}></div>}
        </div>
      )}
      {!authenticatedUser && showLoginForm && <SignInForm />}
      {!authenticatedUser && showSignUpForm && <SignUpForm />}
    </>
  );
};

export default NextButton;
