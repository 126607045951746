import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import axios from "axios";

const EditarDatosPersonales = () => {
  const { authenticatedUser } = useAuth();
  const [cambiarClave, setCambiarClave] = useState();
  const [changeDireccion, setChangeDireccion] = useState();
  const [formData, setFormData] = useState({
    id: "",
    nombre: "",
    apellido: "",
    telefono: "",
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    direccion: "",
    distrito: ""
  });
  const Navigate = useNavigate();

  useEffect(() => {
    if (authenticatedUser) {
      setFormData({
        id: authenticatedUser.id,
        nombre: authenticatedUser.nombre,
        apellido: authenticatedUser.apellido,
        telefono: authenticatedUser.telefono,
        email: authenticatedUser.email,
        newPassword: "",
        confirmNewPassword: "",
        direccion: "",
        distrito: ""
      });
    }
  }, [authenticatedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualiza el estado solo para el campo que se está cambiando
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que las contraseñas coincidan si es necesario
    if (formData.newPassword !== formData.confirmNewPassword) {
      console.error("Las contraseñas no coinciden.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${formData.id}`, // Ajusta la URL a la de tus usuarios
        formData
      );
      console.log("Respuesta del servidor:", response.data);
      Navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
    }
  };

  return (
    <div className="content-container">
      <div className="modal">
        <div className="modal-content">
          <h2>Editar datos Personales</h2>
          <form onSubmit={handleSubmit} className="">
            <label>
              Nombre
              <input
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                className="form-input"
              />
            </label>

            <label>
              Apellido
              <input
                type="text"
                name="apellido"
                value={formData.apellido}
                onChange={handleChange}
                className="form-input"
              />
            </label>
            <label>
              Teléfono
              <input
                type="text"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                className="form-input"
              />
            </label>

            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-input"
              />
            </label>
            {changeDireccion && (
              <div>
                <label>
                  Direccion
                  <input
                    type="direccion"
                    name="direccion"
                    value={formData.direccion}
                    onChange={handleChange}
                    className="form-input"
                  />
                </label>
                <label>
                  Distrito
                  <input
                    type="distrito"
                    name="distrito"
                    value={formData.distrito}
                    onChange={handleChange}
                    className="form-input"
                  />
                </label>
              </div>
            )}
            {cambiarClave && (
              <div className="cambiar-clave-container">
                <>
                  <label>
                    Nueva contraseña:
                    <input
                      type="password"
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleChange}
                      className="form-input"
                    />
                  </label>
                  <label>
                    Confirmar nueva contraseña:
                    <input
                      type="password"
                      name="confirmNewPassword"
                      value={formData.confirmNewPassword}
                      onChange={handleChange}
                      className="form-input"
                    />
                  </label>
                </>
              </div>
            )}

            <button type="submit">Actualizar datos</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditarDatosPersonales;
