import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const CodeManagement = () => {
  const [codes, setCodes] = useState([]);
  const [newCode, setNewCode] = useState({
    codigo: "",
    porcentaje: 0,
    montoFijo: 0,
    fechaInicio: new Date().toISOString().split('T')[0],
    fechaTermino: new Date().toISOString().split('T')[0],
    usosMax: 1,
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "EEEE, d 'de' MMMM 'de' yyyy HH:mm", { locale: es });
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  const fetchCodes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/codigos`);
      setCodes(response.data);
    } catch (error) {
      console.error("Error al obtener códigos de descuento:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCode((prevCode) => ({
      ...prevCode,
      [name]: value,
    }));
  };

  const handleCreateCode = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/codigos`, newCode);
      setNewCode({
        codigo: "",
        porcentaje: 0,
        montoFijo: 0,
        fechaInicio: new Date().toISOString().split('T')[0],
        fechaTermino: "",
        usosMax: 1,
      });
      fetchCodes();
    } catch (error) {
      console.error("Error al crear el código de descuento:", error);
    }
  };

  const handleDeleteCode = async (codeId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/codigos/${codeId}`);
      fetchCodes();
    } catch (error) {
      console.error("Error al eliminar el código de descuento:", error);
    }
  };

  return (
    <div className="content-container">
      <h2>Administrar Códigos de Descuento</h2>

      <div>
        <h3>Nuevo Código de Descuento</h3>
        <label>
          Código:{" "}
          <input
            type="text"
            name="codigo"
            value={newCode.codigo}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Porcentaje:{" "}
          <input
            type="number"
            name="porcentaje"
            value={newCode.porcentaje}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Monto Fijo:{" "}
          <input
            type="number"
            name="montoFijo"
            value={newCode.montoFijo}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Fecha de Inicio:{" "}
          <input
            type="date"
            name="fechaInicio"
            value={newCode.fechaInicio}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Fecha de Término:{" "}
          <input
            type="date"
            name="fechaTermino"
            value={newCode.fechaTermino}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Usos Máximos:{" "}
          <input
            type="number"
            name="usosMax"
            value={newCode.usosMax}
            onChange={handleInputChange}
          />
        </label>
        <button onClick={handleCreateCode}>Crear Código</button>
      </div>

      <div>
        <h3>Total Códigos de Descuento: </h3>
        <table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Porcentaje</th>
              <th>Monto Fijo</th>
              <th>Fecha Inicio</th>
              <th>Fecha Término</th>
              <th>Usos Actual</th>
              <th>Usos Máx</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {codes.map((code) => (
              <tr key={code.id}>
                <td>{code.codigo}</td>
                <td>{code.porcentaje}%</td>
                <td>S/. {code.montoFijo}</td>
                <td>{formatDate(code.fechaInicio)}</td>
                <td>{formatDate(code.fechaTermino)}</td>
                <td>{code.usosActual}</td>
                <td>{code.usosMax}</td>
                <td>
                  <button onClick={() => handleDeleteCode(code.id)}>
                    Eliminar Código
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CodeManagement;
