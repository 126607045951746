import React, { useState, useEffect } from "react";
import { useShoppingContext } from "../../../context/ShoppingContext";
import axios from "axios";
import "./RegistroPedidos.css";
import "../../../components/Header/Header.css";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import {
  useIsUserWorker,
  useIsUserAdmin,
  useIsUserDev,
} from "../../../context/AuthContext";
import "jspdf-autotable"; // Importa el módulo de autotable para jspdf
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faTimes,
  faFilePdf,
  faBell,
  faBellSlash, // Cambia el icono para exportar a PDF
} from "@fortawesome/free-solid-svg-icons";
import { faWaze, faGoogle } from "@fortawesome/free-brands-svg-icons";
import CategoryTable from "../Categorias/categoryTable";
import PedidoCard from "./PedidoCard";

const RegistroPedidos = () => {
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [lastPedidosCount, setLastPedidosCount] = useState(0);
  const [playedSound, setPlayedSound] = useState(false);
  const [notificationsActive, setNotificationsActive] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showPedidosGrupo, setShowPedidosGrupo] = useState({});

  const pdf = new jsPDF();

  const {
    obtenerUsuarios,
    obtenerPedidos,
    pedidos,
    obtenerProductos,
    productosOriginales,
  } = useShoppingContext();

  const notification = new Audio("sounds/push.mp3");

  const isUserWorker = useIsUserWorker();
  const isUserAdmin = useIsUserAdmin();
  const isUserDev = useIsUserDev();

  // Función para agregar una nueva notificación
  const addNotification = (pedidoId) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      {
        id: pedidoId,
        message: `Nuevo pedido ID: ${pedidoId}`,
      },
    ]);
  };

  // Función para cerrar una notificación
  const closeNotification = (pedidoId) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== pedidoId)
    );
  };

  useEffect(() => {
    // Mostrar la notificación cuando se recibe un nuevo pedido
    if (lastPedidosCount > 0) {
      const newPedidoId = sortedPedidos[0].id; // Suponiendo que el último pedido está al principio de la lista
      addNotification(newPedidoId);
    }
  }, [lastPedidosCount]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await obtenerPedidos();
        await obtenerProductos();
        await obtenerUsuarios();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    // Establecer temporizador para actualizar los pedidos cada cierto intervalo
    const timerId = setInterval(async () => {
      const newPedidos = await obtenerPedidos(); // Obtener los pedidos

      // Verificar si algún pedido tiene el estado "Activo"
      const hasReceivedPedido = newPedidos.some(
        (pedido) => pedido.estado_pedido === "Activo"
      );

      if (notificationsActive && hasReceivedPedido) {
        // Reproducir sonido si las notificaciones están activas y hay un pedido activo
        if (!playedSound) {
          notification.play();
        }
      } else {
        // Silenciar el sonido si las notificaciones están inactivas o no hay pedidos activos
        notification.pause();
        notification.currentTime = 0; // Reiniciar la reproducción desde el principio
      }

      // Actualizar el estado con la nueva cantidad de pedidos
      setLastPedidosCount(newPedidos.length);
    }, 5000); // Actualizar cada 3 segundos

    // Limpiar el temporizador cuando el componente se desmonte
    return () => clearInterval(timerId);
  }, [notificationsActive]); // Dependencia añadida para que el useEffect se ejecute cuando cambie notificationsActive

  const toggleNotifications = () => {
    setNotificationsActive(!notificationsActive);
  };

  const formattedTime = currentDateTime.toLocaleTimeString("es-PE", {
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  const formattedDate = currentDateTime.toLocaleDateString("es-PE", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });

  const formatTime = (createdAt) => {
    return new Date(createdAt).toLocaleTimeString("es-PE", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatDate = (createdAt) => {
    return new Date(createdAt).toLocaleDateString("es-PE", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });
  };

  const handlePedidoClick = async (pedido) => {
    if (selectedPedido && selectedPedido.id === pedido.id) {
      setSelectedPedido(null);
    } else {
      setSelectedPedido(pedido);

      try {
        await obtenerDetalles(pedido.id);
      } catch (error) {
        console.error("Error al obtener los detalles del pedido:", error);
      }
    }
  };

  const actualizarEstadoPedido = async (pedidoId, nuevoEstado) => {
    try {
      if (
        selectedPedido &&
        typeof selectedPedido === "object" &&
        selectedPedido.id
      ) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`,
          {
            estado_pedido: nuevoEstado,
          }
        );

        const nuevosPedidos = pedidos.map((pedido) => {
          if (pedido.id === pedidoId) {
            return { ...pedido, estado_pedido: nuevoEstado };
          }
          return pedido;
        });

        obtenerPedidos(nuevosPedidos);

        console.log(
          `Estado del pedido ${pedidoId} actualizado a ${nuevoEstado}`
        );
      }
    } catch (error) {
      console.error("Error al actualizar el estado del pedido:", error);
    }
  };

  const handleDeletePedido = async (pedidoId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}`
      );
      // Después de eliminar el pedido, actualiza la lista de pedidos
      obtenerPedidos();
    } catch (error) {
      console.error("Error al eliminar el pedido:", error);
    }
  };

  const handleEstadoChange = (e, pedido) => {
    const nuevoEstado = e.target.value;
    setSelectedPedido((prevSelectedPedido) => {
      const updatedSelectedPedido = { ...prevSelectedPedido, ...pedido };
      return updatedSelectedPedido;
    });

    const pedidoId = pedido.id;
    actualizarEstadoPedido(pedidoId, nuevoEstado);
  };

  const sortedPedidos = pedidos.slice().sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  const togglePopUp = () => {
    const popupElement = document.querySelector(".popup");
    if (popupElement) {
      popupElement.classList.toggle("hidden");
    }
  };

  const exportToPdf = () => {
    const doc = new jsPDF();

    const title = "COMBO BLANQUIAZUL - Pedidos Online";

    const columns = [
      { title: "ID Pedido", dataKey: "id" },
      { title: "Nombre", dataKey: "nombre" },
      { title: "Cantidad", dataKey: "cantidad" }, // Agregar columna para detalles
      { title: "Monto total", dataKey: "monto_total" },
      { title: "¿Pagó?", dataKey: "estado_pedido" },
    ];

    // Ordenar primero por estado de pedido y luego alfabéticamente
    const sortedPedidosByEstado = sortedPedidos.sort((a, b) => {
      const order = [
        "Activo",
        "Confirmado",
        "En camino",
        "Entregado",
        "Rechazado",
        "Cancelado",
      ];
      return order.indexOf(a.estado_pedido) - order.indexOf(b.estado_pedido);
    });

    // Calcular el total de todas las ventas
    const totalVentas = sortedPedidosByEstado.reduce(
      (total, pedido) => total + pedido.monto_total,
      0
    );

    // Crear filas para el PDF
    const rows = sortedPedidosByEstado.map((pedido) => ({
      id: pedido.id,
      createdAt: formatDate(pedido.createdAt),
      nombre: pedido.nombre,
      monto_total: pedido.monto_total.toFixed(2),
      cantidad: pedido.monto_total.toFixed(2) / 15,
      estado_pedido: pedido.estado_pedido === "Confirmado" ? "Sí" : "No",
    }));

    doc.text(title, 15, 10); // Agregar el título en la posición (15, 10)

    doc.autoTable(columns, rows, {
      startY: 20,
      margin: { horizontal: 15 },
      styles: { fontSize: 10, cellPadding: 2 },
      columnStyles: { id: { cellWidth: 20 } },
      theme: "grid",
    });

    // Agregar totales fuera de la tabla
    doc.setFont("helvetica", "bold");

    doc.text(
      `Total ventas online: S/. ${totalVentas.toFixed(2)}`,
      15,
      doc.lastAutoTable.finalY + 40
    );

    doc.save("lista-ventas-online.pdf");
  };

  const obtenerDetalles = async (pedidoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}/detalles`
      );
      return response.data;
      console.log("Detalles:", response);
    } catch (error) {
      console.error(
        `Error al obtener los detalles del pedido ${pedidoId}:`,
        error
      );
    }
  };

  // Agrupar los pedidos por estado
  const groupedPedidos = sortedPedidos.reduce((acc, pedido) => {
    const estado = pedido.estado_pedido;
    if (!acc[estado]) {
      acc[estado] = [];
    }
    acc[estado].push(pedido);
    return acc;
  }, {});

  const togglePedidosGrupo = (estado) => {
    setShowPedidosGrupo((prevState) => ({
      ...prevState,
      [estado]: !prevState[estado],
    }));
  };

  return (
    <div className="content-container pedidos-container">
      <div
        className="popup"
        onClick={() => {
          togglePopUp();
          toggleNotifications();
        }}
      >
        <h2>Haz click para comenzar a recibir notificaciones</h2>
        <FontAwesomeIcon
          icon={notificationsActive ? faBell : faBellSlash}
          onClick={toggleNotifications}
        />
      </div>
      <h2 className="registro-pedidos-heading">
        Registro de Pedidos{" "}
        <FontAwesomeIcon icon={notificationsActive ? faBell : faBellSlash} />
      </h2>
      <div className="clock flex-space-around">
        <div>
          <FontAwesomeIcon icon={faClock} /> <span>{formattedTime}</span>{" "}
        </div>

        <div>
          <FontAwesomeIcon icon={faCalendar} /> <span>{formattedDate}</span>{" "}
        </div>

        <button onClick={exportToPdf}>
          Lista digital{" "}
          <span className="pdf">
            <FontAwesomeIcon icon={faFilePdf} />
          </span>
        </button>
      </div>
      {[
        "Activo",
        "Confirmado",
        "En camino",
        "Entregado",
        "Rechazado",
        "Cancelado",
      ].map((estado) => (
        <div key={estado}>
          {groupedPedidos[estado] && groupedPedidos[estado].length > 0 && (
            <h3
              onClick={() => togglePedidosGrupo(estado)}
              className={
                estado === "Activo"
                  ? "pedidos-ordenados bg-green"
                  : estado === "Confirmado"
                  ? "pedidos-ordenados bg-yellow"
                  : estado === "Entregado"
                  ? "pedidos-ordenados bg-gray"
                  : estado === "Rechazado"
                  ? "pedidos-ordenados bg-gray"
                  : estado === "Cancelado"
                  ? "pedidos-ordenados  bg-gray"
                  : "pedidos-ordenados"
              }
            >
              <span>{showPedidosGrupo[estado] ? "▼" : "▶"}</span>{" "}
              {/* Icono para mostrar/ocultar */}
              {estado === "Activo"
                ? "Pedidos nuevos"
                : estado === "Confirmado"
                ? "En preparación"
                : estado === "Entregado"
                ? "Pedidos entregados"
                : estado === "Rechazado"
                ? "Pedidos rechazados"
                : estado === "Cancelado"
                ? "Pedidos cancelados"
                : estado === "En camino"
                ? "En camino / Listo para recoger"
                : estado}
            </h3>
          )}
          {showPedidosGrupo[estado] &&
            groupedPedidos[estado] &&
            groupedPedidos[estado].length > 0 && (
              <>
                {groupedPedidos[estado].map((pedido) => (
                  <PedidoCard
                    key={pedido.id}
                    pedido={pedido}
                    handleEstadoChange={handleEstadoChange}
                    handleDeletePedido={handleDeletePedido}
                    productosOriginales={productosOriginales}
                    obtenerDetalles={obtenerDetalles}
                  />
                ))}
              </>
            )}
        </div>
      ))}
    </div>
  );
};

export default RegistroPedidos;
