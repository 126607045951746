import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./Productos.css";
import EditarProducto from "./EditarProducto";
import { useShoppingContext } from "../../../context/ShoppingContext";

const ListaProductos = () => {
  const [productosFiltrados, setProductosFiltrados] = useState({});
  const [busquedaNombre, setBusquedaNombre] = useState("");
  const [busquedaId, setBusquedaId] = useState("");
  const [busquedaActivo, setBusquedaActivo] = useState("");
  const [busquedaCategoria, setBusquedaCategoria] = useState("");
  const [categorias, setCategorias] = useState({});
  const [productoEditando, setProductoEditando] = useState(null);
  const {
    editando,
    setEditando,
    productosOriginales,
    setProductosOriginales,
    obtenerProductos,
  } = useShoppingContext();

  const [busquedaCategoriaId, setBusquedaCategoriaId] = useState("");
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");

  // Función para obtener el nombre de la categoría seleccionada
  useEffect(() => {
    if (busquedaCategoriaId && categorias[busquedaCategoriaId]) {
      setCategoriaSeleccionada(categorias[busquedaCategoriaId]);
    } else {
      setCategoriaSeleccionada("");
    }
  }, [busquedaCategoriaId, categorias]);

  const filtrarProductos = useCallback(() => {
    let productosFiltradosTemp = {};

    productosOriginales.forEach((producto) => {
      if (!productosFiltradosTemp[producto.categoria_id]) {
        productosFiltradosTemp[producto.categoria_id] = [];
      }
      if (
        (!busquedaNombre ||
          producto.nombre
            .toLowerCase()
            .includes(busquedaNombre.toLowerCase())) &&
        (!busquedaId || producto.producto_id.toString() === busquedaId) &&
        (busquedaActivo === "" ||
          producto.activo === (busquedaActivo === "activo")) &&
        (!busquedaCategoria ||
          producto.categoria_id.toString() === busquedaCategoria)
      ) {
        productosFiltradosTemp[producto.categoria_id].push(producto);
      }
    });

    setProductosFiltrados(productosFiltradosTemp);
  }, [
    busquedaNombre,
    busquedaId,
    busquedaActivo,
    busquedaCategoria,
    productosOriginales,
  ]);

  useEffect(() => {
    filtrarProductos();
  }, [
    busquedaNombre,
    busquedaId,
    busquedaActivo,
    busquedaCategoria,
    productosOriginales,
    filtrarProductos,
  ]);

  const obtenerCategorias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categorias`
      );
      const categoriasData = response.data;
      const categoriasObject = categoriasData.reduce((acc, categoria) => {
        acc[categoria.categoria_id] = categoria.nombre;
        return acc;
      }, {});
      setCategorias(categoriasObject);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  };
  useEffect(() => {
    obtenerProductos();
    obtenerCategorias();
  }, []);

  const toggleActivo = async (producto_id) => {
    try {
      const producto = productosOriginales.find(
        (prod) => prod.producto_id === producto_id
      );
      const updatedProducto = { ...producto, activo: !producto.activo };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/products/${producto_id}`,
        updatedProducto
      );

      obtenerProductos();
    } catch (error) {
      console.error("Error al cambiar el estado activo del producto:", error);
    }
  };

  const handleEditarProducto = (producto) => {
    setEditando(true);
    setProductoEditando(producto);
  };

  const handleCerrarEdicion = () => {
    setEditando(false);
    setProductoEditando(null);
  };

  const handleDeleteProducto = async (producto_id) => {
    try {
      console.log("Deleting product with ID:", producto_id);
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/products/${producto_id}`
      );
      console.log("Product deleted successfully.");

      // After deleting the product, update the list of products
      const nuevosProductos = productosOriginales.filter(
        (producto) => producto.producto_id !== producto_id
      );
      console.log("New products list after deletion:", nuevosProductos);
      setProductosOriginales(nuevosProductos);

      obtenerProductos();
      console.log("Products list updated.");
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  return (
    <div className="products-container">
      {editando && productoEditando && (
        <EditarProducto
          producto={productoEditando}
          onClose={handleCerrarEdicion}
          onUpdate={obtenerProductos}
        />
      )}
      <div className="busqueda-container">
        <input
          type="text"
          placeholder="Buscar productos"
          value={busquedaNombre}
          onChange={(e) => setBusquedaNombre(e.target.value)}
        />
        <div className="filtros">
          <select
            value={busquedaActivo}
            onChange={(e) => setBusquedaActivo(e.target.value)}
          >
            <option value="">Estado</option>
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </select>
          <select
            value={busquedaCategoria}
            onChange={(e) => setBusquedaCategoria(e.target.value)}
          >
            <option value="">Categoría</option>
            {Object.keys(categorias).map((categoriaId) => (
              <option key={categoriaId} value={categoriaId}>
                {categorias[categoriaId]}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="productos-tarjetas-container">
        {Object.keys(productosFiltrados).map((categoriaId) => (
          <div key={categoriaId} className="categoria-container">
            {productosFiltrados[categoriaId].length > 0 && (
              <h2>{categorias[categoriaId]}</h2>
            )}
            <div className="productos-categoria">
              {productosFiltrados[categoriaId].map((producto) => (
                <div key={producto.producto_id} className="producto-tarjeta">
                  <div className="card-content">
                    <div className="producto-info">
                      <div className="producto-estado">
                        <input
                          type="checkbox"
                          checked={producto.activo}
                          onChange={() => toggleActivo(producto.producto_id)}
                        />
                        <span>{producto.activo ? "Activo" : "Inactivo"}</span>
                      </div>

                      <div className="producto-nombre">{producto.nombre}</div>
                      <div className="producto-precio">
                        {producto.precio.toFixed(2)}
                      </div>
                      <div className="producto-actions">
                        <button onClick={() => handleEditarProducto(producto)}>
                          Editar
                        </button>
                        <button
                          onClick={() =>
                            handleDeleteProducto(producto.producto_id)
                          }
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                    <img src={producto.img} alt={producto.img} height={150} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListaProductos;
