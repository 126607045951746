import React, { useState, useEffect } from "react";
import axios from "axios";

const EditarProducto = ({ producto, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({});
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    if (producto) {
      setFormData(producto);
    }
    obtenerCategorias();
  }, [producto]);

  const obtenerCategorias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categorias`
      );
      setCategorias(response.data);
    } catch (error) {
      console.error("Error al obtener las categorías:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/products/${formData.producto_id}`,
        formData
      );
      console.log("Respuesta del servidor:", response.data);

      // Llama a la función onUpdate para actualizar la lista de productos
      onUpdate(response.data);

      // Cierra el modal
      onClose();
    } catch (error) {
      console.error("Error al actualizar el producto:", error);
    }
  };

  return (
    <div className="extras-container">
      <div className="extras-content">
        <h2 className="flex-center">Editar Producto</h2>
        <form className="producto-form" onSubmit={handleSubmit}>
          <div className="flex-center">
          <img src={formData.img} width={250}></img>
          </div>
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="descripcion">Descripción</label>
            <textarea
              id="descripcion"
              name="descripcion"
              value={formData.descripcion || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="stock">Stock</label>
            <input
              type="number"
              id="stock"
              name="stock"
              value={formData.stock || ""}
              onChange={handleChange}
            />
            <label htmlFor="precio">Precio</label>
            <input
              type="number"
              id="precio"
              name="precio"
              value={formData.precio || ""}
              onChange={handleChange}
            />
            {/* <label htmlFor="precio_estadio">Precio tachado:</label>
            <input
              type="number"
              id="precio_estadio"
              name="precio_estadio"
              value={formData.precio_estadio || ""}
              onChange={handleChange}
            /> */}
          </div>
          <div className="form-group">
            <label htmlFor="img">Imagen</label>
            <input
              type="text"
              id="img"
              name="img"
              value={formData.img || ""}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="categoria_id">Categoría</label>
            <select
              required
              id="categoria_id"
              name="categoria_id"
              value={formData.categoria_id}
              onChange={handleChange}
            >
              <option value="">Selecciona una categoría</option>
              {categorias.map((categoria) => (
                <option
                  key={categoria.categoria_id}
                  value={categoria.categoria_id}
                >
                  {categoria.nombre}
                </option>
              ))}
            </select>
          <div className="form-actions">
            <button onClick={onClose}>Cerrar</button>
            <button type="submit">Actualizar Producto</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditarProducto;
