import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationArrow,
  faBook,
  faBookAtlas,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import Loading from "../../components/Loading/Loading";

function Footer() {
  const [loading, setLoading] = useState(true);

  const [filtroCategoria, setFiltroCategoria] = useState(null);

  const [nombreCategoriaFiltrada, setNombreCategoriaFiltrada] = useState("");

  const [productos, setProductos] = useState([]);

  useEffect(() => {
    let params = { activo: true };
    if (filtroCategoria) {
      params.categoria_id = filtroCategoria;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/products/`, {
        params: params,
      })
      .then((response) => {
        const productosData = response.data;
        if (Array.isArray(productosData)) {
          setProductos(productosData);
          setLoading(false);
        } else {
          console.error("No hay productos agregados para esta categoría.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(
          `Error al obtener productos para la categoría ${nombreCategoriaFiltrada}:`,
          error
        );
        setLoading(false);
      });
  }, [filtroCategoria]);

  if (loading) {
    return <Loading text="Cargando productos" />;
  }

  // Número de WhatsApp y mensaje
  const phoneNumber = "+51907386999";
  const message = "¡Hola Mostro's!";

  // Crear la URL de WhatsApp
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;

  return (
    <footer>
      <div className="footer-content">
        <div className="mostros-redes">
          <Link to="/">
            <div className="footer-logo-container">
              <div className="footer-logo-background">
                <img className="footer-logo" src="images/logos/icon.png"></img>
              </div>
            </div>
          </Link>
          <ul className="redes-sociales">
            <li>
              <a
                target="_blank"
                style={{ color: "#fff" }}
                href={"https://instagram.com/mostrosburgers/"}
              >
                Síguenos en Instagram <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href={whatsappUrl}
                target="_blank"
                style={{ color: "#fff" }}
                rel="noopener noreferrer"
              >
                Escríbenos por whatsapp <FontAwesomeIcon icon={faWhatsapp} />
                {/* Agregar el ícono de WhatsApp */}
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <Link to="/">
            <h3>Productos</h3>
          </Link>
          <Link to="/">
            <p>Hamburguesas</p>
          </Link>
          <Link to="/">
            <p>Guarniciones</p>
          </Link>
          <Link to="/">
            <p>Bebidas</p>
          </Link>
        </div>
        <div className="footer-section">
          <h3>Atención al cliente</h3>
          <p>
            <FontAwesomeIcon icon={faPhone} /> +51907386999
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> mostrosburgers@gmail.com
          </p>
          <p>
            <FontAwesomeIcon icon={faLocationArrow} /> Magdalena, Lima, Perú.
          </p>
          <a href="/libro-de-reclamaciones">
            <div className="ldr">
              <img
                height={100}
                src="images/logos/ldr.png"
                alt="Libro de reclamaciones"
              />
            </div>
          </a>
        </div>
      </div>
      <ul className="footer-bottom">
        <li>&copy; 2024 Mostro's</li>
        <li>
          <a href="/terminos-y-condiciones">Términos y condiciones</a>
        </li>
        <li>
          <a href="/politica-de-privacidad">Política de privacidad</a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
