import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

// Crear el contexto
const ShoppingContext = createContext();

// Función para calcular el subtotal de un producto en el carrito
const calcularSubtotal = (precio, cantidad) => {
  return precio * cantidad;
};

// Función para calcular el total del carrito
const calcularTotal = (carrito) => {
  return carrito.reduce((total, item) => {
    // Multiplica la cantidad por el precio y suma al total
    return total + item.precio * item.cantidad;
  }, 0);
};

// Proveedor del contexto que contendrá el estado del carrito
export const ShoppingProvider = ({ children }) => {
  // Guarda una copia de los selectedItems originales
  const [sortBy, setSortBy] = useState(""); // Agregado
  const [selectedItemsOriginales, setSelectedItemsOriginales] = useState([]);
  const [productos, setProductos] = useState([]); // Estado para los productos obtenidos de la API
  const [montoTotal, setMontoTotal] = useState();
  const [costoEnvio, setCostoEnvio] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pedidoId, setPedidoId] = useState(null); // Estado para almacenar el ID del pedido
  const [detallesPedido, setDetallesPedido] = useState([]);
  const [pedidosOriginales, setPedidosOriginales] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [productosOriginales, setProductosOriginales] = useState([]);
  const [usuariosOriginales, setUsuariosOriginales] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [filteredPedidos, setFilteredPedidos] = useState();
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isRotated, setRotated] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showNewProduct, setShowNewProduct] = useState(false);
  const [distrito, setDistrito] = useState("");
  const [showLoginSection, setShowLoginSection] = useState(true);
  const [categorias, setCategorias] = useState([]);
  const [showPopUp, setShowPopUp] = useState(true);
  const [descuento, setDescuento] = useState(0); // Estado para almacenar el descuento aplicado
  const [precioFinalConDescuento, setPrecioFinalConDescuento] = useState(0);
  const [codigoDescuento, setCodigoDescuento] = useState(""); // Nuevo estado para almacenar el código de descuento
  const [subtotal, setSubtotal] = useState(0); // Estado para almacenar el subtotal
  const [metodoPago, setMetodoPago] = useState(""); // Estado para almacenar el subtotal
  const [comprobante, setComprobante] = useState(""); // Estado para almacenar el subtotal
  const [dni, setDni] = useState("");
  const [showElement, setShowElement] = useState(null);
  const [ruc, setRuc] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardExpiration, setCardExpiration] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [instrucciones, setInstrucciones] = useState("");
  const [delivery, setDelivery] = useState(false);
  const [open, setOpen] = useState(true);
  // Cargar el carrito desde localStorage al inicio o crear un carrito vacío si no existe
  const initialCart = JSON.parse(localStorage.getItem("carrito")) || [];
  const [carrito, setCarrito] = useState(initialCart);
  const [showMostros, setShowMostros] = useState(false);
  const [showRecomendados, setShowRecomendados] = useState(false);
  const [estados, setEstados] = useState([]);
  const [selectedExtras, setSelectedExtras] = useState({});
  const [carritoExtras, setCarritoExtras] = useState({});
  const [nombre, setNombre] = useState("");

  const [error, setError] = useState(null);

  const [editando, setEditando] = useState(false);
  const vaciarCarrito = () => {
    setCarrito([]); // Establece el carrito como un arreglo vacío
  };

  // Guardar el carrito en localStorage cada vez que cambie
  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(carrito));
  }, [carrito]);

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
    if (cartOpen) {
      toggleCart();
    }
  };

  const fetchEstados = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/estados`
      );
      setEstados(response.data);
    } catch (error) {
      console.error("Error al obtener los estados:", error);
    }
  };

  const agregarCategoria = async (categoria) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/categorias`, {
        nombre,
      });
      // Limpiar el campo de nombre después de crear la categoría
      setNombre(categoria);
      // Actualizar el estado de las categorías cargando nuevamente la lista
      obtenerCategorias();
    } catch (error) {
      // Manejar errores de solicitud, por ejemplo, mostrar un mensaje de error al usuario
      setError(error.response.data.error);
    }
  };

  const agregarAlCarrito = (menuItem) => {
    // Check if the item already exists in the cart
    const itemExistente = carrito.find((item) => {
      // Check if the product IDs are the same
      if (item.producto_id !== menuItem.producto_id) return false;

      // Check if the extras are the same
      if (!item?.extras || !menuItem?.extras) return false; // Verificar si extras están definidos

      if (item.extras.length !== menuItem.extras.length) return false;

      for (let i = 0; i < item.extras.length; i++) {
        const existingExtra = item.extras[i];
        const newExtra = menuItem.extras.find(
          (extra) => extra.id === existingExtra.id
        );

        if (!newExtra || newExtra.cantidad !== existingExtra.cantidad) {
          return false;
        }
      }

      handleAplicarDescuento();

      return true;
    });

    if (itemExistente) {
      // If the item exists, update the quantity of the existing item
      const nuevoCarrito = carrito.map((item) => {
        if (
          item.producto_id === menuItem.producto_id &&
          extrasIguales(item.extras, menuItem.extras)
        ) {
          return { ...item, cantidad: item.cantidad + 1 };
        }
        return item;
      });
      setCarrito(nuevoCarrito);
    } else {
      // If the item does not exist, add it with quantity 1
      setCarrito([...carrito, { ...menuItem, cantidad: 1 }]);
    }
  };

  // Función para verificar si los extras son iguales
  const extrasIguales = (extras1, extras2) => {
    if (extras1.length !== extras2.length) return false;

    for (let i = 0; i < extras1.length; i++) {
      const extra1 = extras1[i];
      const extra2 = extras2.find((extra) => extra.id === extra1.id);

      if (!extra2 || extra1.cantidad !== extra2.cantidad) {
        return false;
      }
    }

    return true;
  };

  const toggleCart = () => {
    setCartOpen(!cartOpen);
    if (userMenuOpen) {
      toggleUserMenu();
    }
    if (showPopUp) {
      setShowPopUp(false);
    }
  };

  const closeCart = () => {
    setCartOpen(false);
  };

  const modificarCantidadHamburguesa = (index, cantidad) => {
    // Verifica que el índice sea válido
    if (index >= 0 && index < carrito.length) {
      const item = carrito[index];

      if (item.categoria_id === 1) {
        const nuevaCantidad = item.cantidad + cantidad;

        // Asegúrate de que la cantidad no sea menor que 0
        const cantidadNoNegativa = nuevaCantidad < 0 ? 0 : nuevaCantidad;

        // Copia el carrito original y actualiza el elemento en el índice dado
        const nuevoCarrito = [...carrito];
        nuevoCarrito[index] = { ...item, cantidad: cantidadNoNegativa };

        // Filtra los elementos con cantidad 0 del nuevo carrito
        const nuevoCarritoFiltrado = nuevoCarrito.filter(
          (item) => item.cantidad > 0
        );

        setCarrito(nuevoCarritoFiltrado);
      }
    }
  };

  const modificarCantidad = (itemId, cantidad) => {
    // Actualiza la cantidad del elemento en el carrito
    const nuevoCarrito = carrito.map((item) => {
      if (item.producto_id === itemId) {
        const nuevaCantidad = item.cantidad + cantidad;
        // Asegúrate de que la cantidad no sea menor que 0
        const cantidadNoNegativa = nuevaCantidad < 0 ? 0 : nuevaCantidad;
        if (cantidadNoNegativa === 0) {
          // Elimina el elemento si la cantidad llega a 0
          return null;
        }
        return { ...item, cantidad: cantidadNoNegativa };
      }
      return item;
    });

    // Filtra los elementos nulos (productos con cantidad 0) del nuevo carrito
    const nuevoCarritoFiltrado = nuevoCarrito.filter((item) => item !== null);

    setCarrito(nuevoCarritoFiltrado);
  };

  const eliminarDelCarrito = (itemId) => {
    // Verificar si el itemId es válido
    const itemIndex = carrito.findIndex((item) => item.producto_id === itemId);
    if (itemIndex !== -1) {
      // Crear una copia del carrito actual excluyendo el elemento con el itemId especificado
      const nuevoCarrito = [
        ...carrito.slice(0, itemIndex),
        ...carrito.slice(itemIndex + 1),
      ];

      // Actualizar el carrito con el nuevo arreglo que excluye el producto eliminado
      setCarrito(nuevoCarrito);

      // En este punto, también debes eliminar el elemento correspondiente en selectedItems
      // Para hacerlo, puedes usar el mismo itemIndex para mantener sincronizados los arreglos
      const nuevosSelectedItems = [
        ...selectedItems.slice(0, itemIndex),
        ...selectedItems.slice(itemIndex + 1),
      ];

      // Actualizar selectedItems con los nuevos valores
      setSelectedItems(nuevosSelectedItems);

      handleAplicarDescuento();
    }
  };

  const calcularDescuento = (codigoEncontrado) => {
    let descuentoCalculado = 0;
    if (codigoEncontrado.porcentaje !== 0) {
      descuentoCalculado =
        (codigoEncontrado.porcentaje / 100) * calcularSubtotal();
    } else if (codigoEncontrado.montoFijo !== 0) {
      descuentoCalculado = codigoEncontrado.montoFijo;
    }
    return descuento;
  };

  const handleAplicarDescuento = async () => {
    try {
      if (!codigoDescuento) {
        setError("Ingresa un código de descuento");
        return;
      }
      if (descuento) {
        setError("Ya se aplicó un descuento");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/codigos`
      );
      const codigosDescuento = response.data;

      const codigoEncontrado = codigosDescuento.find(
        (codigo) => codigo.codigo === codigoDescuento
      );

      if (!codigoEncontrado) {
        setError("El código de descuento no existe");
        return;
      }

      if (codigoEncontrado.usosActual >= codigoEncontrado.usosMax) {
        setError("El código de descuento ha alcanzado su límite de usos");
        return;
      }

      const descuentoAplicado = calcularDescuento(codigoEncontrado);
      setDescuento(descuentoAplicado);

      codigoEncontrado.usosActual++;
      await axios.put(
        `${process.env.REACT_APP_API_URL}/codigos/${codigoEncontrado.id}`,
        codigoEncontrado
      );

      setMontoTotal(precioFinalConDescuento);
      setError(`Cupón usado: ${codigoEncontrado.codigo}`);
    } catch (error) {
      console.error("Error al aplicar el código de descuento:", error);
      setError(
        "Error al aplicar el código de descuento. No existe o está mal escrito."
      );
    }
  };

  const handleItemSelectedChange = (index, field, value) => {
    const updatedItems = [...selectedItems];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setSelectedItems(updatedItems);

    // Guarda los datos en el localStorage
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
  };

  const obtenerUsuarios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      const usuarios = response.data;
      setUsuariosOriginales(usuarios);
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
    }
  };

  // Función para obtener los pedidos
  const obtenerPedidos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos`
      );
      const listaPedidos = response.data;
      setPedidos(listaPedidos);

      return listaPedidos; // Devuelve la lista de pedidos
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
      return []; // Devuelve un arreglo vacío en caso de error
    }
  };

  const obtenerProductos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      const productos = response.data;
      setProductosOriginales(productos);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const obtenerDetallesPedido = async (pedidoId, ruta) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/${pedidoId}/detalles`
      );

      if (ruta === "/registro-de-pedidos") {
        // Establece la respuesta completa en detallesPedido
        setDetallesPedido(response.data);
      } else {
        // Establece solo la propiedad 'detalles' de la respuesta en detallesPedido
        setDetallesPedido(response.data.detalles);
      }
    } catch (error) {
      console.error("Error al obtener los detalles del pedido:", error);
    }
  };

  const obtenerMisPedidos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/mis-pedidos`,
        {
          withCredentials: true,
          credentials: "include", // Agrega este encabezado
        }
      );

      const listaPedidos = response.data;

      if (Array.isArray(listaPedidos)) {
        setPedidosOriginales(listaPedidos);
        setPedidosFiltrados(listaPedidos);
      } else {
        console.error("La respuesta no es un array:", listaPedidos);
      }
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
    }
  };

  const eliminarHamburguesa = (indice) => {
    // Verificar si el índice es válido
    if (indice >= 0 && indice < carrito.length) {
      // Crear una copia del carrito actual excluyendo el elemento en el índice especificado
      const nuevoCarrito = [
        ...carrito.slice(0, indice),
        ...carrito.slice(indice + 1),
      ];

      // Actualizar el carrito con el nuevo arreglo que excluye el menuItem eliminado
      setCarrito(nuevoCarrito);

      // Crear una copia de selectedItems excluyendo el elemento en el índice especificado
      const nuevosSelectedItems = [
        ...selectedItems.slice(0, indice),
        ...selectedItems.slice(indice + 1),
      ];

      // Actualizar selectedItems con los nuevos valores
      setSelectedItems(nuevosSelectedItems);

      handleAplicarDescuento();
    }
  };

  const obtenerCategorias = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categorias`
      );
      setCategorias(response.data); // Almacena las categorías en el estado
    } catch (error) {
      console.error("Error al obtener la lista de categorías:", error);
    }
  };

  const cargarProductos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`
      );
      setProductos(response.data);
    } catch (error) {
      console.error("Error al obtener la lista de productos:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleRecomendados = () => {
    setShowRecomendados(!showRecomendados);
  };

  const toggleMostros = () => {
    setShowMostros(!showMostros);
  };

  // Función para calcular el envío por distrito
  const calcularEnvio = (distrito) => {
    // Define los costos de envío por distrito
    const costosEnvioPorDistrito = {
      Recojo: 0,
      Barranco: 11,
      "Jesús María": 11,
      "La Molina": 16,
      "La Victoria": 10,
      Lince: 8,
      Magdalena: 7,
      Miraflores: 9,
      "Pueblo Libre": 10,
      "San Borja": 11,
      "San Isidro": 7,
      "San Luis": 10,
      "San Miguel": 9,
      "Santiago de Surco": 12,
      Surquillo: 10,
    };

    // Verifica si el distrito está en la lista, si no, usa un valor predeterminado
    const CostoEnvioPorDistrito = costosEnvioPorDistrito[distrito] || 0;
    return CostoEnvioPorDistrito;
  };

  const [extras, setExtras] = useState([]); // Estado para almacenar los extras obtenidos de la API

  // Función para cargar los extras desde la API
  const cargarExtras = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/extras`
      );
      const extrasData = response.data;
      setExtras(extrasData);
    } catch (error) {
      console.error("Error al cargar los extras:", error);
    }
  };

  const [editarVisible, setEditarVisible] = useState(false); // Estado para mostrar u ocultar el formulario de edición
  const [editingExtra, setEditingExtra] = useState(null); // Estado para almacenar el extra que se está editando

  // Función para mostrar u ocultar el formulario de edición
  const toggleEditar = () => {
    setEditarVisible(!editarVisible);
  };

  return (
    <ShoppingContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
        toggleSidebar,
        carrito,
        vaciarCarrito,
        agregarAlCarrito,
        modificarCantidad,
        modificarCantidadHamburguesa,
        eliminarDelCarrito,
        calcularSubtotal,
        calcularTotal,
        selectedItems,
        pedidoId,
        usuariosOriginales,
        productosOriginales,
        pedidosOriginales,
        pedidosFiltrados,
        pedidos,
        setSelectedItems,
        setPedidoId,
        setUsuariosOriginales,
        setProductosOriginales,
        setPedidosOriginales,
        setPedidos,
        setPedidosFiltrados,
        handleItemSelectedChange,
        productos,
        setProductos,
        detallesPedido,
        setDetallesPedido,
        obtenerDetallesPedido,
        obtenerUsuarios,
        obtenerProductos,
        obtenerPedidos,
        obtenerMisPedidos,
        selectedItemsOriginales,
        setSelectedItemsOriginales,
        toggleCart,
        closeCart,
        cartOpen,
        setCartOpen,
        toggleUserMenu,
        userMenuOpen,
        setUserMenuOpen,
        filteredPedidos,
        setFilteredPedidos,
        cartItemCount,
        setCartItemCount,
        isRotated,
        setRotated,
        detailsVisible,
        setDetailsVisible,
        showLoginSection,
        setShowLoginSection,
        costoEnvio,
        setCostoEnvio,
        montoTotal,
        setMontoTotal,
        distrito,
        setDistrito,
        sortBy,
        setSortBy,
        showLoginForm,
        setShowLoginForm,
        showSignUpForm,
        setShowSignUpForm,
        eliminarHamburguesa,
        categorias,
        setCategorias,
        obtenerCategorias,
        cargarProductos,
        calcularEnvio,
        showPopUp,
        setShowPopUp,
        precioFinalConDescuento,
        setPrecioFinalConDescuento,
        descuento,
        setDescuento,
        codigoDescuento,
        setCodigoDescuento,
        subtotal,
        setSubtotal,
        metodoPago,
        setMetodoPago,
        cardNumber,
        setCardNumber,
        cardName,
        setCardName,
        cardExpiration,
        setCardExpiration,
        cardCvv,
        setCardCvv,
        comprobante,
        setComprobante,
        dni,
        ruc,
        razonSocial,
        setDni,
        setRuc,
        setRazonSocial,
        instrucciones,
        setInstrucciones,
        delivery,
        setDelivery,
        open,
        setOpen,
        showRecomendados,
        setShowRecomendados,
        toggleRecomendados,
        toggleMostros,
        showMostros,
        setShowMostros,
        fetchEstados,
        estados,
        setEstados,
        extras,
        cargarExtras,
        setExtras,
        editarVisible,
        setEditarVisible,
        toggleEditar,
        editingExtra,
        setEditingExtra,
        selectedExtras,
        setSelectedExtras,
        carritoExtras,
        setCarritoExtras,
        showElement,
        setShowElement,
        showNewProduct,
        setShowNewProduct,
        editando,
        setEditando,
        productosOriginales,
        setProductosOriginales,
        agregarCategoria,
        nombre,
        setNombre,
        handleAplicarDescuento,
        calcularDescuento,
      }}
    >
      {children}
    </ShoppingContext.Provider>
  );
};

// Hook personalizado para acceder al contexto
export const useShoppingContext = () => {
  return useContext(ShoppingContext);
};
