import React, { useEffect, useState } from "react";
import axios from "axios";
import { useShoppingContext } from "../../../context/ShoppingContext";
import EditarExtra from "./EditarExtra";

const ExtraList = () => {
  const { extras, cargarExtras, toggleEditar, editingExtra, setEditingExtra } =
    useShoppingContext();

  const [formData, setFormData] = useState({
    nombre: "",
    precio: "",
  });

  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    cargarExtras();
  }, []);

  const handleEditClick = (extra) => {
    toggleEditar();
    setEditingExtra(extra);
  };

  const handleDeleteClick = async (extra) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/extras/${extra.id}`, {
        withCredentials: true,
      });
      cargarExtras();
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  const agregarExtra = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/extras`, formData, {
        withCredentials: true,
      });

      setFormData({
        nombre: "",
        precio: "",
      });

      cargarExtras();
      setShowAddForm(false);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleAddForm = () => {
    setShowAddForm(!showAddForm);
  };

  return (
    <div>
      <table className="extra-table">
        <thead>
          <tr>
            <th>Extra para burger</th>
            <th>Precio</th>
            <th>Activo</th>
            <th>Agotado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {extras.map((extra) => (
            <tr key={extra.id}>
              <td>{extra.nombre}</td>
              <td>{(extra.precio).toFixed(2)}</td>
              <td>{extra.activo ? "Sí" : "No"}</td>
              <td>{extra.agotado ? "Sí" : "No"}</td>
              <td>
                <button onClick={() => handleEditClick(extra)}>Editar</button>
                <button onClick={() => handleDeleteClick(extra)}>Eliminar</button>
              </td>
            </tr>
          ))}

          <tr>
            <td>
              {showAddForm ? (
                <input
                  required
                  placeholder="Nuevo Extra"
                  type="text"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                />
              ) : null}
            </td>
            <td>
              {showAddForm ? (
                <input
                  required
                  placeholder="Precio"
                  type="number"
                  name="precio"
                  value={formData.precio}
                  onChange={handleChange}
                />
              ) : null}
            </td>
            <td></td>
            <td></td>
            <td>
              {showAddForm ? (
                <button onClick={agregarExtra}>Agregar</button>
              ) : (
                <button onClick={toggleAddForm}>Agregar Nuevo</button>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {editingExtra && <EditarExtra />}
    </div>
  );
};

export default ExtraList;
