import React, { useState, useEffect } from "react";
import axios from "axios";
import SignInForm from "../../../components/User/SignInForm";
import ListaUsuarios from "./ListaUsuarios"; // Asegúrate de ajustar la importación al componente correcto
import "../../../styles.css";

const AgregarUsuarios = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    direccion: "",
    distrito: "",
    password: "",
    telefono: "",
    level: "",
  });

  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [error, setError] = useState(null); // Estado para manejar errores
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    cargarUsuarios();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const cargarUsuarios = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users`
      );
      setUsuarios(response.data);
    } catch (error) {
      console.error("Error al obtener la lista de usuarios:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validar el formulario aquí antes de enviar la solicitud

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/createUser`,
        formData
      );

      console.log("Respuesta del servidor:", response.data);

      // Limpiar el formulario después de registrar el usuario
      setFormData({
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        direccion: "",
        distrito: "",
        password: "",
        level: "",
      });

      // Actualizar la lista de usuarios después de registrar uno nuevo
      window.location.reload();
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      setError(
        "Error al registrar el usuario. Verifica los datos e intenta nuevamente."
      );
    }
  };

  return (
    <div className="content-container">
      <ListaUsuarios usuarios={usuarios} />
      <div>
        {/* Mostrar mensaje de error si existe */}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default AgregarUsuarios;
